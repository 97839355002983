import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import {
  Clients,
  CreateProducts,
  DashboardDailyRoute,
  Drivers,
  Home,
  MakeRoute,
  Order,
  Orders,
  Product,
  Products,
  Sellers,
  NotFoundPage,
  OrderDetails,
  ReportGeneration,
  LoginPage,
  DashboardLayout,
} from "./pages";

import { PrivateRoute } from "./components";

import "./App.css";
import customTheme from "./theme";
import locale from "antd/es/locale/es_ES";

import { AuthProvider } from "./contexts/AuthProvider";

const App = () => {
  return (
    <AuthProvider>
      <ConfigProvider theme={customTheme} locale={locale}>
        <Router>
          <Routes>
            {/* Login */}
            <Route path="/login" element={<LoginPage />} />

            {/* Dashboard */}
            <Route
              path="*"
              element={
                <DashboardLayout>
                  <Routes>
                    <Route
                      path="/"
                      element={<PrivateRoute element={<Home />} />}
                    />
                    <Route
                      path="/home"
                      element={<PrivateRoute element={<Home />} />}
                    />
                    <Route
                      path="/orders"
                      element={<PrivateRoute element={<Orders />} />}
                    />
                    <Route
                      path="/orders/:id"
                      element={<PrivateRoute element={<OrderDetails />} />}
                    />
                    <Route
                      path="/products"
                      element={<PrivateRoute element={<Products />} />}
                    />
                    <Route
                      path="/products/create"
                      element={<PrivateRoute element={<CreateProducts />} />}
                    />
                    <Route
                      path="/products/:product_id"
                      element={<PrivateRoute element={<Product />} />}
                    />
                    <Route
                      path="/sellers"
                      element={<PrivateRoute element={<Sellers />} />}
                    />
                    <Route
                      path="/clients"
                      element={<PrivateRoute element={<Clients />} />}
                    />
                    <Route
                      path="/drivers"
                      element={<PrivateRoute element={<Drivers />} />}
                    />
                    <Route
                      path="/makeroute"
                      element={<PrivateRoute element={<MakeRoute />} />}
                    />
                    <Route
                      path="/reportgeneration"
                      element={<PrivateRoute element={<ReportGeneration />} />}
                    />
                    <Route
                      path="/dashboardDailyRoute/:routeDay"
                      element={
                        <PrivateRoute element={<DashboardDailyRoute />} />
                      }
                    />
                    <Route path="/notFoundPage" element={<NotFoundPage />} />
                  </Routes>
                </DashboardLayout>
              }
            />
          </Routes>
        </Router>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default App;
