import api from "../api/axios";

export const fetchOrders = async (createdDate = null, deliveryDate = null) => {
  console.log("createdDate inside fetch Order", createdDate);
  console.log("deliveryDate inside fetch Order", deliveryDate);

  const formattedCreatedDate = createdDate ? "createdAt=" + createdDate : "";

  const formattedDeliveryDate = deliveryDate
    ? "deliveryDate=" + deliveryDate
    : "";

  const result = await api.get(
    "/orders/" + formattedCreatedDate + formattedDeliveryDate
  );
  return result.data;
};

export const fetchOrderByID = async (id) => {
  const response = await api.get("/orders/" + id);
  const result = await response.data;
  console.log(result);
  return result;
};

export const removeOrders = async (ordersID) => {
  const val = await api.post("/orders/delete", { order_id: ordersID });
  console.log(val);
  return val;
};

export const createOrder = async (rawOrder) => {
  // const order
  const order = {
    daily_id: rawOrder.daily_id,
    name: rawOrder.name,
    address: rawOrder.name,
    amount: rawOrder.amount,
    city: rawOrder.city,
    phone_number: rawOrder.phone_number,
    products: ["120 kg Safari - lavanda", "20 kg Minino - lavanda"],
    // products: [
    //   { product_name: "easy clean", variant: "lavanda", quantity: 6 },
    //   { product_name: "minino", variant: "lavanda", quantity: 1 },
    // ],
    seller: rawOrder.seller,
    payment: "Pagado", // Pendiente
    observation: "Dejar en conserjería", // Pendiente
    raw: "Orden insertada por Business Copilot",
  };
  console.log(order);
  const val = api.post("/orders/create", order);
  return val;
};

export const getGroupedOrders = (orders, driversTemplate) => {
  console.log("Entrando a getGrpuedOders");
  const ordersByCity = orders.reduce((acc, order) => {
    acc[order.city] = (acc[order.city] || 0) + 1;
    return acc;
  }, {});

  // Create initial data structure
  const initialData = {
    groups: {},
    groupOrder: [],
  };

  driversTemplate.forEach(({ driver, city }) => {
    if (!initialData.groups[driver]) {
      initialData.groups[driver] = {
        id: driver,
        title: driver,
        cards: [],
      };
      initialData.groupOrder.push(driver);
    }
    console.log("ordersbyCity", ordersByCity);
    if (ordersByCity[city] > 0) {
      initialData.groups[driver].cards.push({
        id: city,
        content: city,
        number: ordersByCity[city],
      });
    }
  });

  console.log(initialData);
  return initialData;
};

const initialData = {
  groups: {
    Victor: {
      id: "group-1",
      title: "Group 1",
      cards: [
        { id: "card-1", content: "Card 1", number: 10 },
        { id: "card-2", content: "Card 2", number: 20 },
        { id: "card-3", content: "Card 3", number: 30 },
      ],
    },
    "group-2": {
      id: "group-2",
      title: "Group 2",
      cards: [
        { id: "card-4", content: "Card 4", number: 40 },
        { id: "card-5", content: "Card 5", number: 50 },
      ],
    },
    "group-3": {
      id: "group-3",
      title: "Group 3",
      cards: [],
    },
    "group-4": {
      id: "group-4",
      title: "Group 4",
      cards: [],
    },
    "group-5": {
      id: "group-5",
      title: "Group 5",
      cards: [],
    },
  },
  groupOrder: ["group-1", "group-2", "group-3", "group-4", "group-5"],
};

export const assignDriverToOrders = async (
  groupedData,
  groupedDataMayorista,
  orders,
  drivers,
  ordersMinorista,
  ordersMayorista
) => {
  const groups = groupedData.groups;
  const groupsMayorista = groupedDataMayorista.groups;
  const result = [];
  const resultMayorista = [];
  const driversCity = Object.keys(groups).map((driver) => ({
    driver_name: driver,
    cities: groups[driver].cards.map((card) => card.id),
  }));
  const driversCityMayorista = Object.keys(groupsMayorista).map((driver) => ({
    driver_name: driver,
    cities: groupsMayorista[driver].cards.map((card) => card.id),
  }));

  driversCity.forEach((item) => {
    const driver_id = drivers.filter(
      (driver) => driver.name === item.driver_name
    )[0].personnel_id;
    item.cities.forEach((city) => {
      const cityOrders = ordersMinorista.filter((order) => order.city === city);
      cityOrders.forEach((order) => {
        result.push({
          delivery_id:
            order.delivery_info_array[order.delivery_info_array.length - 1]
              .delivery_id,
          driver_id: driver_id,
        });
      });
    });
  });

  driversCityMayorista.forEach((item) => {
    const driver_id = drivers.filter(
      (driver) => driver.name === item.driver_name
    )[0].personnel_id;
    item.cities.forEach((city) => {
      const cityOrders = ordersMayorista.filter((order) => order.city === city);
      cityOrders.forEach((order) => {
        resultMayorista.push({
          delivery_id:
            order.delivery_info_array[order.delivery_info_array.length - 1]
              .delivery_id,
          driver_id: driver_id,
        });
      });
    });
  });

  // const result2 = ordersGreater200.map((order) => {
  //   return {
  //     delivery_id:
  //       order.delivery_info_array[order.delivery_info_array.length - 1]
  //         .delivery_id,
  //     driver_id: 22,
  //   };
  // });

  console.log("result", result); // Remove when conneted to database
  console.log("result 2", resultMayorista);

  const val = await api.post("/orders/edit/driver", { delivery_info: result });
  console.log("val", val); // Remove when conneted to database

  const val2 = await api.post("/orders/edit/driver", {
    delivery_info: resultMayorista,
  });

  console.log("val2", val2); // Remove when conneted to database
  return val2;
};

export const editOrderByID = async (obj) => {
  const data = {
    order_id: obj.order_id,
    daily_id: obj.daily_id,
    name: obj.name,
    address: obj.address,
    amount: obj.amount,
    city: obj.city,
    seller_id: obj.seller_id,
  };
  const val = await api.post("/orders/edit", data);
  console.log("val", val);
  return val;
};

export const addOrderProduct = async (orderID, productToAdd) => {
  const data = { order_id: Number(orderID), product: productToAdd };
  console.log("data", data);
  const val = await api.post("/orders/add/product", data);
  return val;
};

export const deleteOrderProduct = async (orderProductID) => {
  const data = { order_product_id: Number(orderProductID) };
  const val = await api.post("/orders/remove/product", data);
  return val;
};

export const editDeliveryOrder = async (deliveryInfo) => {
  const data = {
    delivery_id: deliveryInfo.delivery_id,
    delivery_status: deliveryInfo.delivery_status,
    delivery_date: deliveryInfo.delivery_date,
    delivery_note: deliveryInfo.delivery_note,
    driver_id: deliveryInfo.driver_id,
  };

  const val = await api.post("/orders/edit/delivery", data);

  return val;
};

export const addDeliveryOrder = async (deliveryInfo) => {
  const data = {
    order_id: deliveryInfo.order_id,
    delivery_status: deliveryInfo.delivery_status,
    delivery_date: deliveryInfo.delivery_date,
    delivery_note: deliveryInfo.delivery_note,
    driver: deliveryInfo.driver_id,
  };

  const val = await api.post("/orders/add/delivery", data);

  console.log("val", val);
  return val;
};

export const deleteDeliveryOrder = async (deliveryID) => {
  const data = {
    delivery_id: deliveryID,
  };

  const val = await api.post("/orders/delete/delivery", data);

  // console.log("val", val);
  return val;
};

const apiUrls = {
  deliveryDate: "orders/detail/",
  createdAt: "pending",
};

export const fetchOrdersInitialEndDate = async (dates, type) => {
  const apiUrl = apiUrls[type] + dates.initialDate + "/" + dates.endDate;
  const val = await api.get(apiUrl);
  return val;
};
