import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const PaymentInfoCard = ({ paymentInfo, setPaymentInfo }) => {
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [editingPayment, setEditingPayment] = useState(null);
  const [form] = Form.useForm();

  // Workaround for ResizeObserver loop limit exceeded issue
  useEffect(() => {
    const handleResizeObserverError = () => {
      window.requestAnimationFrame(() => {
        window.dispatchEvent(new Event("resize"));
      });
    };

    window.addEventListener("resize", handleResizeObserverError);

    return () => {
      window.removeEventListener("resize", handleResizeObserverError);
    };
  }, []);

  const handleAddPayment = () => {
    // setEditingPayment(null);
    // form.resetFields();
    // setIsPaymentModalVisible(true);
    message.warning("Esta opción aún no está disponible.");
  };

  const handleEditPayment = (item) => {
    // setEditingPayment(item);
    // form.setFieldsValue(item);
    // setIsPaymentModalVisible(true);
    message.warning("Esta opción aún no está disponible.");
  };

  const handleDeletePayment = (payment_id) => {
    // const newPayments = paymentInfo.filter(
    //   (item) => item.payment_id !== payment_id
    // );
    // setPaymentInfo(newPayments);
    message.warning("Esta opción aún no está disponible.");
  };

  const handlePaymentModalOk = (values) => {
    let newPayments;
    if (editingPayment) {
      newPayments = paymentInfo.map((item) =>
        item.payment_id === editingPayment.payment_id
          ? { ...item, ...values }
          : item
      );
    } else {
      newPayments = [...paymentInfo, { ...values, payment_id: Date.now() }];
    }
    setPaymentInfo(newPayments);
    setIsPaymentModalVisible(false);
  };

  const handlePaymentModalCancel = () => {
    setIsPaymentModalVisible(false);
  };

  return (
    <Card>
      <List
        itemLayout="horizontal"
        dataSource={paymentInfo}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleEditPayment(item)}
              />,
              <Popconfirm
                title="Are you sure to delete this payment?"
                onConfirm={() => handleDeletePayment(item.payment_id)}
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  disabled={paymentInfo.length === 1}
                />
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              title={`${item.payment_method} - ${item.amount}`}
              description={`Date: ${
                item.payment_date || "Sin Fecha"
              }, Status: ${
                item.payment_status ? "No disponible" : "No disponible"
              }`}
            />
          </List.Item>
        )}
      />
      <Button
        type="primary"
        onClick={handleAddPayment}
        icon={<PlusOutlined />}
        style={{ marginTop: 16 }}
      >
        Añadir Pago
      </Button>
      <Modal
        title={editingPayment ? "Edit Payment" : "Add Payment"}
        visible={isPaymentModalVisible}
        onCancel={handlePaymentModalCancel}
        footer={null}
      >
        <Form
          form={form}
          initialValues={
            editingPayment || {
              payment_method: "",
              payment_date: "",
              amount: 0,
              payment_status: "",
            }
          }
          onFinish={handlePaymentModalOk}
        >
          <Form.Item
            name="payment_method"
            label="Payment Method"
            rules={[
              { required: true, message: "Please input the payment method!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="payment_date"
            label="Payment Date"
            rules={[
              { required: true, message: "Please input the payment date!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: "Please input the amount!" }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="payment_status"
            label="Payment Status"
            rules={[
              { required: true, message: "Please input the payment status!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingPayment ? "Save" : "Add"}
            </Button>
            <Button
              onClick={handlePaymentModalCancel}
              style={{ marginLeft: "8px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default PaymentInfoCard;
