import React, { useState, useEffect } from "react";

import { Button, Checkbox, DatePicker, message, Divider, Select } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";

import { fromDatetoYearMonthDay } from "../utils/utils";

import { fetchOrdersInitialEndDate } from "../utils/fetchOrders";
import {
  generateExcelFromJSONData,
  generateExcelFromObject,
} from "../utils/generateExcel";
import { fetchSellers } from "../utils/fetchSellers";
import { fetchDrivers } from "../utils/fetchDrivers";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const comunas = [
  "LA FLORIDA",
  "LA PINTANA",
  "PUENTE ALTO",
  "SAN BERNARDO",
  "LA REINA",
  "LAS CONDES",
  "PROVIDENCIA",
  "SANTIAGO CENTRO",
  "VITACURA",
  "LO BARNECHEA",
  "CERRO NAVIA",
  "CONCHALI",
  "HUECHURABA",
  "INDEPENDENCIA",
  "QUILICURA",
  "RECOLETA",
  "RENCA",
  "CERRILLOS",
  "ESTACION CENTRAL",
  "LO PRADO",
  "MAIPU",
  "PUDAHUEL",
  "QUINTA NORMAL",
  "EL BOSQUE",
  "LA GRANJA",
  "PEDRO AGUIRRE CERDA",
  "SAN JOAQUIN",
  "SAN MIGUEL",
  "SAN RAMON",
  "LA CISTERNA",
  "LO ESPEJO",
  "MACUL",
  "ÑUÑOA",
  "PEÑALOLEN",
  "PEÑAFLOR",
  "PADRE HURTADO",
];

const columnsMap = (object) => {
  return {
    NOMBRE: object.name,
    DIRECCION: object.address,
    COMUNA: object.city,
    NUMERO: object.phone_number,
    VALOR: object.amount,
    // EST_PAGO: object.payment_info_array[0].payment_method,
    // VENDEDOR: object.seller_name,
    // PRODUCTO: object.products.map((product) =>
    //   `${product.quantity} x ${product.product_name}`
    // ).join(", "),
    // AROMA: object.products.map((product) => product.variant).join(", "),
    // OBSERVACIÓN: object.delivery_info_array[0].delivery_note,
  };
};

const NewReportButton = ({
  children,
  reportName,
  setCurrentReport,
  currentReport,
}) => {
  return (
    <Button
      className="w-full flex justify-center items-center h-24 rounded-2xl"
      type="dashed"
      onClick={() => {
        setCurrentReport(reportName);
      }}
    >
      {children}
    </Button>
  );
};

const NewReportFormDeliveryDate = ({ currentReport, setCurrentReport }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    sellers: { active: false, values: [] },
    drivers: { active: false, values: [] },
    cities: { active: false, values: [] },
  });
  const [sellers, setSellers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const newValue =
      selectedDates.initialDate &&
      selectedDates.endDate &&
      (filters.sellers.active ? filters.sellers.values.length > 0 : true) &&
      (filters.drivers.active ? filters.drivers.values.length > 0 : true) &&
      (filters.cities.active ? filters.cities.values.length > 0 : true);
    setIsFormValid(newValue);
  }, [filters, selectedDates]);

  useEffect(async () => {
    try {
      const newSellers = await fetchSellers();
      setSellers(newSellers);
      const newDrivers = await fetchDrivers();
      setDrivers(newDrivers);
    } catch (error) {
      process.env.DEBUG_MODE && console.log(error);
      message.error("Error al obtener la información.");
    } finally {
      // setLoading(false);
    }
  }, []);

  //   const handleColumnsChange = (event, item) => {
  //     if (event.target.checked) {
  //       const newSelectedColumns = [
  //         ...selectedColumns,
  //         { title: item.title, key: item.key },
  //       ];
  //       setSelectedColumns(newSelectedColumns);
  //       console.log(newSelectedColumns);
  //     } else {
  //       const newSelectedColumns = selectedColumns.filter(
  //         (column) => column !== item.key
  //       );
  //       setSelectedColumns(newSelectedColumns);
  //       console.log(newSelectedColumns);
  //     }
  //   };

  const handleChangeSelectedDates = (date, type) => {
    const newDate = new Date(date);
    const newSelectedDates = {
      ...selectedDates,
      [type]: fromDatetoYearMonthDay(newDate),
    };
    setSelectedDates(newSelectedDates);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const val = await fetchOrdersInitialEndDate(
        selectedDates,
        "deliveryDate"
      );
      let data = val.data;
      data = filters.sellers.active
        ? data.filter((item) => filters.sellers.values.includes(item.seller_id))
        : data;

      data = filters.drivers.active
        ? data.filter((item) =>
            item.delivery_info_array.some((delivery) =>
              filters.drivers.values.includes(delivery.driver_id)
            )
          )
        : data;

      data = filters.cities.active
        ? data.filter((item) => filters.cities.values.includes(item.city))
        : data;

      console.log(data);
      // const fileName = `Reporte ${selectedDates.initialDate} a ${selectedDates.endDate}.xlsx`;
      // generateExcelFromObject(data, columnsMap, fileName);
    } catch (error) {
      message.error("Error al descargar el reporte. Intente nuevamente.");
    } finally {
      setLoading(false);
      setCurrentReport("");
    }
  };

  const handleActiveFilter = (filterName, boolean) => {
    setFilters((filters) => ({
      ...filters,
      [filterName]: { ...filters[filterName], active: boolean },
    }));
  };

  const handleAddValuesFilter = (filterName, filterValues) => {
    setFilters((filters) => ({
      ...filters,
      [filterName]: { ...filters[filterName], values: filterValues },
    }));
  };

  return (
    <div className="py-5">
      <div className="text-xl">Reporte Fecha de Entrega</div>
      <Divider />
      {/* <div className="">Selecciona la información para el reporte</div> */}
      {/* <List size="small" className="w-48">
        {reportColums.map((item) => (
          <List.Item>
            <Checkbox onChange={(event) => handleColumnsChange(event, item)} />
            <div>{item.title}</div>
          </List.Item>
        ))}
      </List> */}
      <div className="text-lg">Selecciona las fechas</div>
      <div className="flex w-full px-5 py-3 space-x-5">
        <DatePicker
          onChange={(value) => handleChangeSelectedDates(value, "initialDate")}
        />{" "}
        <ArrowRightOutlined className="text-[#4048F1]" />{" "}
        <DatePicker
          onChange={(value) => handleChangeSelectedDates(value, "endDate")}
          disabled={!selectedDates.initialDate}
          minDate={dayjs(selectedDates.initialDate)}
          //   minDate={new Date(selectedDates.initialDate)}
        />
      </div>
      <Divider />
      <div className="text-lg">Filtros</div>
      <div className="px-5">
        <div className="flex gap-3 items-center my-1">
          <Checkbox
            value={filters.sellers.active}
            onChange={(value) => {
              const checked = value.target.checked;
              handleActiveFilter("sellers", checked);
            }}
          />
          <div className="w-40">Filtro por Vendedor</div>
          <Select
            mode="multiple"
            className="w-80"
            disabled={!filters.sellers.active}
            options={sellers?.map((item) => {
              return { value: item.personnel_id, label: item.name };
            })}
            value={filters.sellers.values}
            onChange={(value) => {
              console.log(value);
              handleAddValuesFilter("sellers", value);
            }}
          />
        </div>
        <div className="flex gap-3 items-center my-1">
          <Checkbox
            onChange={(value) => {
              const checked = value.target.checked;
              handleActiveFilter("drivers", checked);
            }}
          />{" "}
          <div className="w-40">Filtro por Chofer</div>
          <Select
            mode="multiple"
            className="w-80"
            disabled={!filters.drivers.active}
            options={drivers?.map((item) => {
              return { value: item.personnel_id, label: item.name };
            })}
            value={filters.drivers.values}
            onChange={(value) => {
              console.log(value);
              handleAddValuesFilter("drivers", value);
            }}
          />
        </div>
        <div className="flex gap-3 items-center my-1">
          <Checkbox
            onChange={(value) => {
              const checked = value.target.checked;
              handleActiveFilter("cities", checked);
            }}
          />{" "}
          <div className="w-40">Filtro por Comuna</div>
          <Select
            mode="multiple"
            className="w-80"
            disabled={!filters.cities.active}
            options={comunas?.map((comuna) => {
              return { value: comuna, label: comuna };
            })}
            value={filters.cities.values}
            onChange={(value) => {
              console.log(value);
              handleAddValuesFilter("cities", value);
            }}
          />
        </div>
      </div>
      <Button onClick={() => console.log(filters)}>Check Filters</Button>
      <div className="py-5 flex space-x-5">
        <Button
          danger
          type="text"
          onClick={() => {
            setCurrentReport("");
          }}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={handleDownload}
          disabled={!isFormValid}
          loading={loading}
        >
          Descargar
        </Button>
      </div>
    </div>
  );
};

const NewReportFormCreatedAt = ({ currentReport, setCurrentReport }) => {
  return (
    <div>
      <div className="font-bold text-xl">Reporte Fecha de Agendamiento</div>
      <Button
        danger
        type="text"
        onClick={() => {
          setCurrentReport("");
        }}
      >
        Cancelar
      </Button>
      <Button type="primary">Generar</Button>
    </div>
  );
};

const ReportGeneration = () => {
  const [currentReport, setCurrentReport] = useState("");
  return (
    <div>
      <div className="font-bold text-3xl">Reportes</div>
      {!currentReport && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-5 space-y-3 space-x-0 md:space-y-0 md:space-x-3">
          <NewReportButton
            currentReport={currentReport}
            setCurrentReport={setCurrentReport}
            reportName={"deliveryDate"}
          >
            <div>
              <div>Pedidos por</div>
              <div className="font-bold">Fecha de Entrega</div>
            </div>
          </NewReportButton>
          {/* <NewReportButton
            currentReport={currentReport}
            setCurrentReport={setCurrentReport}
            reportName={"createdAt"}
          >
            <div>
              <div>Pedidos por</div>
              <div className="font-bold">Fecha de Agendamiento</div>
            </div>
          </NewReportButton> */}
        </div>
      )}
      {currentReport === "deliveryDate" && (
        <NewReportFormDeliveryDate
          currentReport={currentReport}
          setCurrentReport={setCurrentReport}
        />
      )}
      {currentReport === "createdAt" && (
        <NewReportFormCreatedAt
          currentReport={currentReport}
          setCurrentReport={setCurrentReport}
        />
      )}
    </div>
  );
};

export default ReportGeneration;
