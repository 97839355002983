import * as XLSX from "xlsx";

export const generateExcelFromJSONData = (
  data,
  deliveryDate,
  fileName = null
) => {
  console.log("esto es data dentro de la función Descargar Excel", data);
  const dataToExcel = data.map((item) => {
    return {
      ID: item.daily_id,
      NOMBRE: item.name?.toUpperCase(),
      DIRECCION: item.address.split(",")[0]?.toUpperCase(),
      DEPTO: item.address.split(",")[1]?.toUpperCase().trim(),
      COMUNA: item.city?.toUpperCase(),
      NUMERO:
        "(9) " +
        item.phone_number
          ?.replace(/\s/g, "")
          .substring(
            item.phone_number?.replace(/\s/g, "").length - 8,
            item.phone_number?.replace(/\s/g, "").length - 4
          ) +
        " " +
        item.phone_number
          ?.replace(/\s/g, "")
          .substring(
            item.phone_number?.replace(/\s/g, "").length - 4,
            item.phone_number?.replace(/\s/g, "").length
          ),
      PRODUCTO:
        item.products?.length > 0
          ? item.products
              .sort((a, b) => a.product_name.localeCompare(b.product_name))
              .map(
                (product) =>
                  product.quantity + " x " + product.product_name?.toUpperCase()
              )
              .join(" + ")
          : "",
      AROMA:
        item.products?.length > 0
          ? item.products
              .map((product) => product.variant?.toUpperCase())
              .join(" + ")
          : "",
      VALOR: item.amount ? item.amount : "",
      EST_PAGO: item.payment_info_array
        ? item.payment_info_array[0].payment_method.toUpperCase()
        : "",
      VENDEDOR: item.seller_name?.toUpperCase(),
      OBSERVACIÓN: item.delivery_info_array
        ? item.delivery_info_array[
            item.delivery_info_array?.length - 1
          ].delivery_note?.toUpperCase()
        : "",
    };
  });
  const worksheet = XLSX.utils.json_to_sheet(dataToExcel);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  const nameOfFile = fileName || `${deliveryDate}_pedidos.xlsx`;

  XLSX.writeFile(workbook, nameOfFile);
};

export const generateExcelFromObject = (arrayObjects, columnMap, fileName) => {
  const dataToExcel = arrayObjects.map((object) => columnMap(object));
  const worksheet = XLSX.utils.json_to_sheet(dataToExcel);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  XLSX.writeFile(workbook, fileName);
};
