import React, { useState } from "react";
import {
  List,
  Input,
  Button,
  Form,
  Popconfirm,
  message,
  Card,
  Row,
  Col,
  Switch,
  Typography,
  Tag,
  Select,
  Badge,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { editStock } from "../../utils/fetchProducts";

const { Text } = Typography;

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"red"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

const VariantsProducts = ({
  variants,
  setVariants,
  loadingState,
  requestVariants,
  addVariantCallback,
  deleteVariantCallback,
}) => {
  const { loadingVariants, setLoadingVariants } = loadingState;

  const [isEditing, setIsEditing] = useState(null);
  const [inputValues, setInputValues] = useState({ variant: "" });

  const [showAddVariantForm, setShowAddVariantForm] = useState(false);

  const [form] = Form.useForm();

  const handleEdit = (item) => {
    setIsEditing(item);
    setInputValues({ variant: item.variant });
  };

  // const handleSave = () => {
  //   if (isEditing.variant === "") {
  //     setVariants([...variants, { variant: inputValues.variant }]);
  //     message.success("Variant added successfully!");
  //   } else {
  //     setVariants(
  //       variants.map((item) =>
  //         item.variant === isEditing.variant
  //           ? { ...item, variant: inputValues.variant }
  //           : item
  //       )
  //     );
  //     message.success("Variant updated successfully!");
  //   }
  //   setIsEditing(null);
  // };

  const handleDelete = async (item) => {
    console.log("Delete", item);
    setLoadingVariants(true);
    await deleteVariantCallback(item); // wait till this resolves
  };

  const handleCancel = () => {
    setIsEditing(null);
  };

  const stockOptions = [
    { value: true, label: "Hay Stock" },
    { value: false, label: "Sin Stock" },
  ];

  const handleEditStock = async (variantID, stockValue) => {
    setLoadingVariants(true);
    try {
      const response = await editStock(variantID, stockValue);
      requestVariants();
      message.success("Stock modificado correctamente!");
      // setLoadingVariants(false);
    } catch (err) {
      console.error("Error al editar stock:", err);
      message.error("Error al editar stock. Recargue la página.");
      setLoadingVariants(false);
    }
  };

  const addVariant = async (vals) => {
    setLoadingVariants(true);
    const formData = { ...vals, stock: vals.stock ? vals.stock : false };
    await addVariantCallback(formData); // wait till this resolves
    setShowAddVariantForm(false);
  };

  return (
    <div className="w-full">
      {variants.map((variant, index) => (
        <Card key={index} style={{ marginBottom: 16 }} className="">
          <Row align="middle" className="">
            <Col span={8} className="flex-wrap">
              <Text strong className={loadingVariants ? "text-gray-400" : ""}>
                {variant.variant}
              </Text>
            </Col>
            <Col span={6}>
              {variant.stock ? (
                <Tag color="green">Stock</Tag>
              ) : (
                <Tag color="red">Sin Stock</Tag>
              )}
            </Col>
            <Col
              span={7}
              align="left"
              className="flex space-x-2 flex-row-reverse"
            >
              <Switch
                defaultChecked={variant.stock}
                onChange={(value) => {
                  handleEditStock(variant.id, value);
                }}
                disabled={loadingVariants}
              />
            </Col>
            <Col span={1} offset={2} style={{ textAlign: "right" }}>
              <Popconfirm
                title="Atención! Esta acción no se puede deshacer."
                onConfirm={() => handleDelete(variant)}
                okText="Eliminar"
                okButtonProps={{
                  style: { backgroundColor: "red", borderColor: "red" },
                }} // Custom red background color
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  disabled={loadingVariants}
                />
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      ))}
      {showAddVariantForm ? (
        <Form
          disabled={loadingVariants}
          onFinish={(vals) => {
            addVariant(vals);
          }}
        >
          <Card style={{ marginBottom: 16 }} className="items-center">
            <Row align="middle" className="">
              <Col span={12} className="">
                <Form.Item
                  className="m-0"
                  name="variant"
                  rules={[
                    {
                      required: true,
                      message: "Debes ingresar un nombre para la variante.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Badge />
              </Col>
              <Col
                span={7}
                align="left"
                className="flex space-x-2 items-center"
              >
                <div className="">Stock:</div>
                <Form.Item name="stock" className="m-0">
                  <Switch defaultValue={false} />
                </Form.Item>
              </Col>
              <Col span={1} offset={2} style={{ textAlign: "right" }}>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="m-0">
                  <Button
                    htmlType="submit"
                    type="link"
                    icon={<SaveOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Button onClick={() => setShowAddVariantForm(false)}>Cancelar</Button>
        </Form>
      ) : (
        <Button
          type="dashed"
          onClick={() => {
            setShowAddVariantForm(true);
            // message.warning("Esta funcionalidad aún no está disponible.");
          }}
          icon={<PlusOutlined />}
          style={{ width: "100%", marginTop: 6, height: "50px" }}
          disabled={loadingVariants}
        >
          Añadir Variante
        </Button>
      )}
    </div>
  );
};

export default VariantsProducts;
