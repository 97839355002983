import React, { useState, useEffect } from "react";

import { Header, DataTable } from "../components";
import { Button } from "antd";
import { ThreeDot } from "react-loading-indicators";
import { fetchOrders, removeOrders } from "../utils/fetchOrders";
import { fetchDrivers } from "../utils/fetchDrivers";
import { fromDatetoYearMonthDay } from "../utils/utils";

import { List } from "antd";

import {
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { useParams, useNavigate } from "react-router-dom";

import {
  generateRoutePDF,
  generateExcelAllDrivers,
  generateLoadDriverPDF,
} from "../utils/generatePDFs";

const DashboardDailyRoute = () => {
  const navigate = useNavigate();
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const params = useParams();
  const routeDay = params.routeDay;

  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true); // change it to false when data fetched
  const [dataError, setDataError] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(async () => {
    console.log(params);
    console.log(params.routeDay);
    setIsDataLoading(true);
    setDataError(null);
    // generateLoadDriverPDF(orders, driver, date);
    const fetchData = async () => {
      try {
        const vals = await fetchOrders(null, routeDay);
        setData(vals);
        const drivers = await fetchDrivers();
        setDrivers(drivers);
        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setDataError(error);
        setIsDataLoading(false);
      }
    };
    fetchData();
  }, [refreshPage]);

  const columns = [
    { header: "ID", accessorKey: "daily_id", footer: "ID" },
    { header: "Nombre", accessorKey: "name", footer: "Name" },
    { header: "Dirección", accessorKey: "address", footer: "Adress" },
    { header: "Comuna", accessorKey: "city", footer: "City" },
    {
      header: "Productos",
      accessorKey: "products",
      footer: "Products",
      cell: ({ row }) =>
        row.original.products !== undefined &&
        row.original.products
          .filter((item) => item.product_name !== "NotFound")
          .map((item) => (
            <>
              {item.product_name} <br />
            </>
          )),
    },
  ];

  const handleRemoveOrders = async (orders) => {
    const removeOrdersID = orders.map((item) => item.order_id);
    try {
      removeOrders(removeOrdersID).then((value) => {
        setRefreshPage(!refreshPage);
      });
    } catch (error) {
      throw Error(error.message);
    }
  };

  const handleDownloadAllRoutes = () => {
    drivers.forEach((driver) => {
      if (
        data?.filter(
          (item) =>
            item.delivery_info_array[item.delivery_info_array.length - 1]
              .driver_id === driver.personnel_id
        ).length > 0
      ) {
        generateRoutePDF(
          // This should be according to the route day
          data?.filter(
            (item) =>
              item.delivery_info_array[item.delivery_info_array.length - 1]
                .driver_id === driver.personnel_id
          ),
          driver.name,
          routeDay
        );
      }
    });
  };

  const handleDownloadAllLoads = () => {
    drivers.forEach((driver) => {
      if (
        data?.filter(
          (item) =>
            item.delivery_info_array[item.delivery_info_array.length - 1]
              .driver_id === driver.personnel_id
        ).length > 0
      ) {
        generateLoadDriverPDF(
          data?.filter(
            (item) =>
              item.delivery_info_array[item.delivery_info_array.length - 1]
                .driver_id === driver.personnel_id
          ),
          driver.name,
          routeDay
        );
      }
    });
  };

  return (
    <>
      <div className="">
        {/* Showing data saved correctly */}
        <div className="flex w-full mb-3">
          <Button
            icon={<SyncOutlined />}
            onClick={() =>
              setRefreshPage((currentState) => {
                return !currentState;
              })
            }
          ></Button>
        </div>
        <div className="">
          {isDataLoading ? (
            <div className="w-full text-center min-h-72 flex items-center justify-center">
              <ThreeDot
                variant="brick-stack"
                color="#4048F1"
                size="medium"
                text=""
                textColor=""
              />
            </div>
          ) : dataError ? (
            <div>Error al cargar pedidos.</div>
          ) : (
            <>
              <div className="w-full grid grid-cols-1 md:grid md:grid-cols-4 space-x-10">
                <div className="col-span-3">
                  <div className="text-xl font-bold">Lista de Choferes</div>
                  <List>
                    {drivers.map((driver) => {
                      if (driver.name !== "No asignado") {
                        return (
                          <List.Item className="flex justify-start">
                            <div className="w-10">{driver.name}</div>
                            <div>
                              <Button
                                onClick={() => {
                                  generateRoutePDF(
                                    // This should be according to the route day
                                    data?.filter(
                                      (item) =>
                                        item.delivery_info_array[
                                          item.delivery_info_array.length - 1
                                        ].driver_id === driver.personnel_id
                                    ),
                                    driver.name,
                                    routeDay
                                  );
                                }}
                                disabled={
                                  data?.filter(
                                    (item) =>
                                      item.delivery_info_array[
                                        item.delivery_info_array.length - 1
                                      ].driver_id === driver.personnel_id
                                  ).length === 0
                                }
                              >
                                Descargar Ruta
                              </Button>
                              <Button
                                onClick={() => {
                                  generateLoadDriverPDF(
                                    data?.filter(
                                      (item) =>
                                        item.delivery_info_array[
                                          item.delivery_info_array.length - 1
                                        ].driver_id === driver.personnel_id
                                    ),
                                    driver.name,
                                    routeDay
                                  );
                                }}
                                disabled={
                                  data?.filter(
                                    (item) =>
                                      item.delivery_info_array[
                                        item.delivery_info_array.length - 1
                                      ].driver_id === driver.personnel_id
                                  ).length === 0
                                }
                              >
                                Descargar Carga
                              </Button>
                            </div>
                          </List.Item>
                        );
                      }
                    })}
                  </List>
                </div>
                <div className="flex flex-col space-y-3 col-span-1">
                  <div className="text-xl font-bold">Descarga Masiva</div>

                  <div className="flex w-full justify-between rounded-xl">
                    <div className="flex items-center space-x-2 ">
                      <FileExcelOutlined className="text-lg" />
                      <div>
                        Descargar <span className="font-bold">Excel</span>
                      </div>
                    </div>
                    <Button
                      onClick={() => generateExcelAllDrivers(data, drivers)}
                    >
                      <DownloadOutlined />
                    </Button>
                  </div>

                  <div className="flex w-full justify-between rounded-xl">
                    <div className="flex items-center space-x-2 ">
                      <FilePdfOutlined className="text-lg" />
                      <div>
                        Descargar todas las{" "}
                        <span className="font-bold">Rutas</span>
                      </div>
                    </div>
                    <Button onClick={handleDownloadAllRoutes}>
                      <DownloadOutlined />
                    </Button>
                  </div>

                  <div className="flex w-full justify-between rounded-xl">
                    <div className="flex items-center space-x-2 ">
                      <FilePdfOutlined className="text-lg" />
                      <div>
                        Descargar todas las{" "}
                        <span className="font-bold">Cargas</span>
                      </div>
                    </div>
                    <Button onClick={handleDownloadAllLoads}>
                      <DownloadOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardDailyRoute;
