// src/components/Login.js
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import axios from "../api/axios";
import { useAuth } from "../contexts/AuthProvider";

import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const LoginPage = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(localStorage.getItem("token"));
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    setError(null);

    // Only for development
    login("a");
    navigate("/orders"); // Redirect to home page

    try {
      const response = await axios.post("/auth/login", {
        email: values.email,
        password: values.password,
      });
      console.log("response", response);
      // arreglar despues
      if (response.data.status === "error") {
        setError("Login failed. Please check your credentials and try again.");
      } else {
        const token = response.data.access_token;
        login(token);
        navigate("/orders"); // Redirect to home page
      }
    } catch (err) {
      setError("Login failed. Please check your credentials and try again.");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <Title level={2} style={{ textAlign: "center" }}>
          Iniciar Sesión
        </Title>
        <Form name="login" onFinish={onFinish} style={{ marginTop: 20 }}>
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Ingresar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f2f5",
  },
  formContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "2rem",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default LoginPage;
