import React from "react";
import { Card, Descriptions, Table, Tabs, Typography, Row, Col } from "antd";

const { Title } = Typography;
const { TabPane } = Tabs;

const order = {
  order_id: 1788,
  daily_id: "101",
  name: "Claudia Tapia Peñafiel ",
  created_at: "2024-07-05T14:31:23.000Z",
  client_id: 353,
  address: "Cerro del paso norte 820",
  amount: 17990,
  city: "SAN BERNARDO",
  order_source: "Whatsapp",
  seller_name: "Eduardo",
  phone_number: "945200361",
  raw: "101\nClaudia Tapia Peñafiel \nCerro del paso norte 820\nSan Bernardo\n945200361\n* 20kg top k9 - lavanda\nTarjeta\nEduardo",
  products: [
    {
      order_product_id: 2005,
      status: "found",
      quantity: 1,
      not_found_name: null,
      product_name: "Manga 20kg Top K9",
      variant: "Lavanda",
      price: 17990,
      not_found_variant: null,
    },
  ],
  payment_info_array: [
    {
      amount: 0,
      payment_id: 1295,
      payment_date: null,
      payment_method: "Tarjeta",
      payment_status: "false",
    },
  ],
  delivery_info_array: [
    {
      driver_id: 0,
      delivery_id: 1253,
      delivery_date: "2024-07-06",
      delivery_note: null,
      delivery_status: "Pendiente de entrega",
    },
  ],
};

const Order2 = ({}) => {
  const columnsProducts = [
    { title: "Product Name", dataIndex: "product_name", key: "product_name" },
    { title: "Variant", dataIndex: "variant", key: "variant" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Price", dataIndex: "price", key: "price" },
  ];

  const columnsPayment = [
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const columnsDelivery = [
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    {
      title: "Delivery Status",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      key: "delivery_note",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={2}>Order Details</Title>

      <Card style={{ marginBottom: "20px" }}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="Order ID">
            {order.order_id}
          </Descriptions.Item>
          <Descriptions.Item label="Daily ID">
            {order.daily_id}
          </Descriptions.Item>
          <Descriptions.Item label="Client Name">
            {order.name}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {order.created_at}
          </Descriptions.Item>
          <Descriptions.Item label="Client ID">
            {order.client_id}
          </Descriptions.Item>
          <Descriptions.Item label="Address">{order.address}</Descriptions.Item>
          <Descriptions.Item label="City">{order.city}</Descriptions.Item>
          <Descriptions.Item label="Order Source">
            {order.order_source}
          </Descriptions.Item>
          <Descriptions.Item label="Seller Name">
            {order.seller_name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {order.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Total Amount">
            {order.amount}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Products" key="1">
          <Card>
            <Table
              dataSource={order.products}
              columns={columnsProducts}
              pagination={false}
              rowKey="order_product_id"
            />
          </Card>
        </TabPane>
        <TabPane tab="Payment Information" key="2">
          <Card>
            <Table
              dataSource={order.payment_info_array}
              columns={columnsPayment}
              pagination={false}
              rowKey="payment_id"
            />
          </Card>
        </TabPane>
        <TabPane tab="Delivery Information" key="3">
          <Card>
            <Table
              dataSource={order.delivery_info_array}
              columns={columnsDelivery}
              pagination={false}
              rowKey="delivery_id"
            />
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default Order2;
