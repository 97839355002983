// src/contexts/AuthProvider.js
import React, { createContext, useState, useContext } from "react";
import TokenExpiredModal from "../components/TokenExpiredModal";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
    window.location.href = "/login";
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuth(true);
    }
    window.showModal = showModal; // Expose showModal globally
  }, []);

  const login = (token) => {
    localStorage.removeItem("token");
    localStorage.setItem("token", token);
    setAuth(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
      <TokenExpiredModal visible={modalVisible} onLogin={hideModal} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
