import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const Header = ({ category, title }) => {
  return (
    <div className="">
      <Title level={1} className=" text-gray-600">
        {title}
      </Title>
    </div>
  );
};

export default Header;
