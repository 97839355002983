import api from "../api/axios";

export const fetchSellers = async () => {
  const response = await api.get("/personnel/all");
  // if (!response.ok) {
  //   throw new Error("Error al conectar con el servidor");
  //   return response;
  // }
  const result = response.data;
  console.log(result);
  // if (result.status === "404") {
  //   throw new Error("Error al cargar los productos.");
  // }
  const sellers = result.filter((item) => item.position === "VENDEDOR");
  return sellers;
};
