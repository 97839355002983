import React, { useState } from "react";
import {
  Timeline,
  Button,
  Input,
  DatePicker,
  Form,
  Typography,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;

const DeliveryTimeline = ({ deliveryInfo, setDeliveryInfo }) => {
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const isEditing = (record) => record.delivery_id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      delivery_date: record.delivery_date ? moment(record.delivery_date) : "",
      delivery_status: "",
      driver: "",
      delivery_note: "",
      ...record,
    });
    setEditingKey(record.delivery_id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (delivery_id) => {
    try {
      const row = await form.validateFields();
      const newData = [...deliveryInfo];
      const index = newData.findIndex(
        (item) => delivery_id === item.delivery_id
      );

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDeliveryInfo(newData);
        setEditingKey("");
      } else {
        newData.push({ ...row, delivery_id: Date.now() });
        setDeliveryInfo(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (delivery_id) => {
    const newDeliveries = deliveryInfo.filter(
      (item) => item.delivery_id !== delivery_id
    );
    setDeliveryInfo(newDeliveries);
  };

  const handleAdd = () => {
    const newRecord = {
      delivery_id: Date.now(),
      delivery_date: "",
      delivery_status: "",
      driver: "",
      delivery_note: "",
    };
    edit(newRecord);
  };

  return (
    <Timeline>
      {deliveryInfo.map((item) => {
        const editable = isEditing(item);
        return (
          <Timeline.Item key={item.delivery_id}>
            {editable ? (
              <Form form={form} component={false}>
                <Form.Item
                  name="delivery_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a delivery date!",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  name="delivery_status"
                  rules={[
                    {
                      required: true,
                      message: "Please input the delivery status!",
                    },
                  ]}
                >
                  <Input placeholder="Delivery Status" />
                </Form.Item>
                <Form.Item
                  name="driver"
                  rules={[
                    {
                      required: true,
                      message: "Please input the driver's name!",
                    },
                  ]}
                >
                  <Input placeholder="Driver" />
                </Form.Item>
                <Form.Item name="delivery_note">
                  <Input placeholder="Delivery Note" />
                </Form.Item>
                <span>
                  <Button
                    onClick={() => save(item.delivery_id)}
                    style={{ marginRight: 8 }}
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                  <Button onClick={cancel} icon={<CloseOutlined />}>
                    Cancel
                  </Button>
                </span>
              </Form>
            ) : (
              <div>
                <Tooltip title={`Driver: ${item.driver}`}>
                  <Text>{item.delivery_date}</Text>
                </Tooltip>
                <Text> - {item.delivery_status}</Text>
                <Text type="secondary"> ({item.delivery_note})</Text>
                <span>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => edit(item)}
                    style={{ marginLeft: 8 }}
                  />
                  <Popconfirm
                    title="Are you sure to delete this delivery?"
                    onConfirm={() => handleDelete(item.delivery_id)}
                  >
                    <Button type="link" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </span>
              </div>
            )}
          </Timeline.Item>
        );
      })}
      <Timeline.Item>
        <Button type="dashed" onClick={handleAdd} icon={<PlusOutlined />}>
          Add Delivery
        </Button>
      </Timeline.Item>
    </Timeline>
  );
};

export default DeliveryTimeline;
