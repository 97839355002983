// src/api/axios.js
import axios from "axios";

const devBackUrl = "http://[::1]:8080";
const baseURL = process.env.REACT_APP_END_POINT;

const api = axios.create({
  baseURL: baseURL, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Include cookies in requests
});

// Request interceptor to add the token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        // console.log("trying to request a new access token with refresh token");
        const response = await axios.post(
          baseURL + "/auth/refresh",
          {},
          {
            withCredentials: true, // Ensure cookies are sent with the request
          }
        );
        // console.log("obtuve esto de respuesta", response);
        const { access_token } = response.data;
        localStorage.removeItem("token");
        localStorage.setItem("token", access_token);
        api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        // console.error("Error al intentar obtener un nuevo token", refreshError);
        if (typeof window.showModal === "function") {
          window.showModal();
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
