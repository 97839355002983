import React, { useState, useEffect } from "react";
import { Typography, Input, InputNumber, Button, AutoComplete } from "antd";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const EditableDescriptionItem = ({
  label,
  value,
  onSave,
  isEditingDefault = false,
  suggestions = [],
  numericInput = false,
  format = null,
}) => {
  const [isEditing, setIsEditing] = useState(isEditingDefault);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const originalValue = value;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onSave(inputValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setInputValue(originalValue); // Reset to current value on cancel
  };

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div className="flex items-center">
      {!isEditing ? (
        <>
          <Text className="min-w">{format ? format(value) : value}</Text>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={handleEdit}
            style={{ marginLeft: 8 }}
          />
        </>
      ) : (
        <>
          {suggestions.length > 0 ? (
            <AutoComplete
              value={inputValue}
              onChange={handleChange}
              options={suggestions.map((suggestion) => ({ value: suggestion }))}
              onKeyPress={handleKeyPress}
              style={{ width: "100%" }}
            >
              <Input onPressEnter={handleSave} />
            </AutoComplete>
          ) : numericInput ? (
            <InputNumber
              value={inputValue}
              onChange={handleChange}
              onPressEnter={handleSave}
              style={{ width: "100%" }}
            />
          ) : (
            <Input
              value={inputValue}
              onChange={(e) => handleChange(e.target.value)}
              onPressEnter={handleSave}
              style={{ width: "100%" }}
            />
          )}
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={handleCancel}
            style={{ marginLeft: 8 }}
          />
        </>
      )}
    </div>
  );
};

export default EditableDescriptionItem;
