import React from "react";

const CustomCard = ({ title, footer, children }) => {
  return (
    <div className="shadow p-4 outline outline-1 outline-gray-200 rounded-xl m-3">
      <div className="text-sm  text-gray-400 pb-2">{title}</div>
      <div className="text-xl font-bold">{children}</div>
      <div>{footer}</div>
    </div>
  );
};

export default CustomCard;
