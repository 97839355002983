// src/components/TokenExpiredModal.js
import React from "react";
import { Modal, Button, Result } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const TokenExpiredModal = ({ visible, onLogin }) => {
  return (
    <Modal open={visible} footer={false}>
      <Result
        status="warning"
        icon={<ExclamationCircleOutlined />}
        title="Sesión Expirada"
        subTitle="Tu sesión ha terminado. Inicia sesión nuevamente para continuar."
        extra={
          <Button type="primary" onClick={onLogin}>
            Ingresar
          </Button>
        }
      />
    </Modal>
  );
};

export default TokenExpiredModal;
