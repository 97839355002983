import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { Input, Button, Switch, message, InputNumber, Upload } from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  editHasOffer,
  editProductGeneralInformation,
} from "../../utils/fetchProducts";

import api from "../../api/axios";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const compressImage = async (file) => {
  const options = {
    maxSizeMB: 0.05, // 0.05MB = 50KB
    maxWidthOrHeight: 1920, // Adjust resolution if needed
    useWebWorker: true, // Enable web worker for better performance
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    // console.error("Image compression failed:", error);
    throw new Error("Compression failed");
  }
};

function getBase64ImageSize(base64String) {
  // Remove the data URL part if it's there
  const base64 = base64String.split(",")[1] || base64String;

  // Calculate the padding count (usually 0, 1, or 2)
  const padding = (base64.match(/=+$/) || []).length;

  // Calculate the base64 string length
  const base64Length = base64.length;

  // Calculate the size in bytes
  const sizeInBytes = (base64Length * 3) / 4 - padding;

  // Convert size from bytes to kilobytes (optional)
  const sizeInKB = sizeInBytes / 1024;

  return {
    bytes: sizeInBytes,
    kilobytes: sizeInKB,
  };
}

const GeneralInformation = ({ data, onSave }) => {
  const [base64Image, setBase64Image] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState({
    name: false,
    matching_string: false,
    weight: false,
    quantity: false,
    is_wholesaler: false,
    wholesaler_qty: false,
    counts_as_manga: false,
  });
  const [inputValues, setInputValues] = useState({});

  const handleUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";

    if (!isJpgOrPng) {
      message.error("Sólo puedes subir imágenes en formato JPG/PNG/WEBP!");
      return false;
    }

    let imageFile = file;

    // If image is larger than 50KB, compress it
    if (file.size / 1024 > 50) {
      try {
        imageFile = await compressImage(file);
        // message.success("Imagen comprimida!");
      } catch (error) {
        message.error("Error al comprimir la imagen.");
        return false;
      }
    }

    // Convert to base64
    const base64 = await getBase64(imageFile);
    const newData = { ...inputValues, image: base64 };
    delete newData.has_offer;

    // console.log("this is newData", newData);
    // console.log("this is the base64 image size", getBase64ImageSize(base64));

    setLoading(true);
    try {
      const val = await editProductGeneralInformation(newData);
      setBase64Image(base64);
      message.success("Image updated successfully!");
    } catch (error) {
      message.error("Error updating image. Please refresh the page.");
      console.error("error", error);
    } finally {
      setLoading(false);
    }

    return false; // Prevent default upload behavior
  };

  useEffect(() => {
    console.log("this is the initial General Information data", data);
    setInputValues({
      product_id: data.product_id,
      name: data.name || "",
      matching_string: data.matching_string || "",
      image: data.image || "",
      has_offer: data.has_offer || false,
      quantity: data.quantity,
      weight: data.weight || 0,
      includes_gift: data.includes_gift || false,
      is_wholesaler: data.is_wholesaler || false,
      wholesaler_qty: data.wholesaler_qty || 999,
      counts_as_manga: data.counts_as_manga || false,
    });
    setBase64Image(data.image);
  }, [data]);

  const handleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleSave = async (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    const newData = { ...inputValues };
    console.log("esto es new data", newData);
    delete newData.has_offer;
    try {
      const val = await editProductGeneralInformation(newData);
      message.success(`Información actualizada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  // Special for has offer
  const handleToggleChange = async (checked) => {
    setInputValues({ ...inputValues, has_offer: checked });
    onSave({ ...data, has_offer: checked });
    try {
      const val = editHasOffer(data.product_id, checked);
      message.success(`Información actualizada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
    } finally {
    }
  };

  const handleCancel = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    setInputValues({ ...inputValues, [field]: data[field] });
  };

  // Value
  const handleChange = async (field, value) => {
    const newData = { ...inputValues, [field]: value };
    console.log("handleChange data", newData);
    setInputValues(newData);
  };

  const toggleValue = async (field, value) => {
    const newData = { ...inputValues, [field]: value };
    console.log("this is new data", newData);
    setInputValues(newData);
    try {
      const postData = { ...newData };
      delete postData.has_offer;
      const val = await editProductGeneralInformation(postData);
      message.success(`Información actualizada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const removeImage = () => {
    const newData = { ...inputValues, image: "" };
    delete newData.has_offer;
    console.log("removeImage newData", newData);
    try {
      const val = editProductGeneralInformation(newData);
      message.success(`Imagen eliminada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
      className="h-48 w-48"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Subir Imagen</div>
    </button>
  );

  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full">
          <StringField
            field="name"
            fieldName="Nombre del Producto"
            inputValues={inputValues}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleChange={handleChange}
            isEditing={isEditing}
          />

          <StringField
            field="matching_string"
            fieldName="Matching String"
            inputValues={inputValues}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleChange={handleChange}
            isEditing={isEditing}
          />

          {/* Special case for the "has_offer" field */}
          <div className="mb-4">
            <label className="block text-gray-700">
              Descuento por cantidad
            </label>
            <Switch
              checked={inputValues.has_offer}
              onChange={handleToggleChange}
            />
          </div>

          <BooleanField
            field="includes_gift"
            fieldName="Incluye Regalo"
            inputValues={inputValues}
            toggleValue={toggleValue}
          />

          <NumberField
            field="weight"
            fieldName="Peso del Articulo"
            inputValues={inputValues}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleChange={handleChange}
            isEditing={isEditing}
          />

          <NumberField
            field="quantity"
            fieldName="Divisor"
            inputValues={inputValues}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleChange={handleChange}
            isEditing={isEditing}
          />

          <BooleanField
            field="is_wholesaler"
            fieldName="Mayorista"
            inputValues={inputValues}
            toggleValue={toggleValue}
          />

          <NumberField
            field="wholesaler_qty"
            fieldName="Cantidad Mayorista"
            inputValues={inputValues}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleChange={handleChange}
            isEditing={isEditing}
          />

          <BooleanField
            field="counts_as_manga"
            fieldName="Cuenta como Manga"
            inputValues={inputValues}
            toggleValue={toggleValue}
          />
        </div>

        {/* Upload Photo */}
        <div
          className={`w-48 h-48 rounded-lg text-center flex items-center transition-all duration-100 ease-in-out ${
            base64Image
              ? " hover:bg-gray-50 hover:outline-1 hover:outline-dashed hover:outline-slate-400"
              : "bg-gray-50 outline-1 outline-dashed outline-slate-400"
          }`}
        >
          <Upload
            beforeUpload={handleUpload}
            // listType="picture-card"
            className="w-48 h-48 flex items-center justify-center"
            showUploadList={false} // Hide the default upload list
          >
            {base64Image ? (
              <div className="text-center flex items-end flex-col">
                <img
                  src={base64Image}
                  alt="Uploaded"
                  className="rounded-lg max-w-48 max-h-48"
                />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        {base64Image && (
          <div className="h-full ml-2">
            <Button
              danger
              type="text"
              className="w-8 h-8"
              onClick={() => {
                removeImage();
                setBase64Image(undefined);
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default GeneralInformation;

const StringField = ({
  field,
  fieldName,
  inputValues,
  handleCancel,
  handleEdit,
  handleSave,
  handleChange,
  isEditing,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">{fieldName}</label>
      {!isEditing[field] ? (
        <div className="flex items-center">
          <span>{inputValues[field]}</span>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEdit(field)}
            style={{ marginLeft: 8 }}
          />
        </div>
      ) : (
        <div className="flex items-center">
          <Input
            value={inputValues[field]}
            onChange={(e) => handleChange(field, e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSave(field);
              }
            }}
          />
          <Button
            type="text"
            icon={<SaveOutlined />}
            onClick={() => handleSave(field)}
            style={{ marginLeft: 8 }}
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => handleCancel(field)}
            style={{ marginLeft: 8 }}
          />
        </div>
      )}
    </div>
  );
};

const NumberField = ({
  field,
  fieldName,
  inputValues,
  handleCancel,
  handleEdit,
  handleSave,
  handleChange,
  isEditing,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">{fieldName}</label>
      {!isEditing[field] ? (
        <div className="flex items-center">
          <span>{inputValues[field]}</span>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEdit(field)}
            style={{ marginLeft: 8 }}
          />
        </div>
      ) : (
        <div className="flex items-center">
          <InputNumber
            value={inputValues[field]}
            onChange={(value) => handleChange(field, value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSave(field);
              }
            }}
          />
          <Button
            type="text"
            icon={<SaveOutlined />}
            onClick={() => handleSave(field)}
            style={{ marginLeft: 8 }}
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => handleCancel(field)}
            style={{ marginLeft: 8 }}
          />
        </div>
      )}
    </div>
  );
};

const BooleanField = ({ field, fieldName, inputValues, toggleValue }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700">{fieldName}</label>
      <Switch
        checked={inputValues[field]}
        onChange={(value) => {
          toggleValue(field, value);
        }}
      />
    </div>
  );
};
