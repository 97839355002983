import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Descriptions,
  Space,
  Button,
  Popover,
  Divider,
  Skeleton,
  message,
} from "antd";

import {
  Header,
  EditableDescriptionItem,
  ProductTable,
  PaymentInfoCard,
  DeliveryInfoCard,
} from "../components";
import { fetchOrderByID, editOrderByID } from "../utils/fetchOrders";
import { fetchSellers } from "../utils/fetchSellers";

const { Title } = Typography;

const comunas = [
  "LA FLORIDA",
  "LA PINTANA",
  "PUENTE ALTO",
  "SAN BERNARDO",
  "LA REINA",
  "LAS CONDES",
  "PROVIDENCIA",
  "SANTIAGO CENTRO",
  "VITACURA",
  "LO BARNECHEA",
  "CERRO NAVIA",
  "CONCHALI",
  "HUECHURABA",
  "INDEPENDENCIA",
  "QUILICURA",
  "RECOLETA",
  "RENCA",
  "CERRILLOS",
  "ESTACION CENTRAL",
  "LO PRADO",
  "MAIPU",
  "PUDAHUEL",
  "QUINTA NORMAL",
  "EL BOSQUE",
  "LA GRANJA",
  "PEDRO AGUIRRE CERDA",
  "SAN JOAQUIN",
  "SAN MIGUEL",
  "SAN RAMON",
  "LA CISTERNA",
  "LO ESPEJO",
  "MACUL",
  "ÑUÑOA",
  "PEÑALOLEN",
  "PEÑAFLOR",
  "PADRE HURTADO",
];

const centeredLabelStyle = {
  display: "flex",
  alignItems: "center",
  height: "100%",
};

const { format } = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const OrderDetails = () => {
  const navigate = useNavigate();
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const { id } = useParams(); // order_id

  const [isCopied, setIsCopied] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  //   const [order, setOrder] = useState(infoOrder);

  const [orderData, setOrderData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [dataError, setDataError] = useState(null);
  const [sellers, setSellers] = useState(null);

  const isEditingDefault = id === "create"; // make it true when create

  useEffect(() => {
    if (id === "create") {
      setIsDataLoading(false);
      // handle creation
    } else {
      setIsDataLoading(true);
      const fetchData = async () => {
        try {
          const vals = await fetchOrderByID(id);
          const valsSellers = await fetchSellers();
          setOrderData(vals[0]);
          setSellers(valsSellers);
          setIsDataLoading(false);
          console.log("Estos son los valotres de val");
          console.log(vals[0]);
        } catch (error) {
          console.log(error);
          setOrderData(null);
          setDataError(error);
          setIsDataLoading(false);
        }
      };
      fetchData();
    }
  }, []);

  const handleSave = async (key, newValue) => {
    messageApi.open({
      type: "loading",
      content: "Actualizando información..",
      duration: 0,
    });
    try {
      const newData = { ...orderData, [key]: newValue };
      const result = await editOrderByID(newData);
      setOrderData((prevOrderData) => ({
        ...prevOrderData,
        [key]: newValue,
      }));
      messageApi.destroy();
      message.success("Información actualizada correctamente.");
    } catch (error) {
      messageApi.destroy();
      message.error("Error al actualizar la información.");
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(orderData.raw).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="">
      {contextHolder}
      <div className="mb-5 flex justify-between">
        <Header category="Página" title="Pedidos" />
      </div>
      <div className="md:grid w-full h-full md:grid-cols-2 grid-cols-1 gap-4 space-y-4 md:space-y-0">
        <div className="bg-white rounded-lg p-4 md:p-6">
          <Title level={4}>General</Title>
          <Divider style={{ margin: "4px" }} />
          {isDataLoading ? (
            <>
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
            </>
          ) : (
            <>
              <Descriptions
                column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                className="mt-4"
              >
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Numero</div>}
                >
                  <EditableDescriptionItem
                    label="daily_id"
                    value={orderData.daily_id}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("daily_id", newValue)}
                    numericInput={true}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Nombre</div>}
                >
                  <EditableDescriptionItem
                    label="Name"
                    value={orderData.name}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("name", newValue)}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Dirección</div>}
                >
                  <EditableDescriptionItem
                    label="Address"
                    value={orderData.address}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("address", newValue)}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Comuna</div>}
                >
                  <EditableDescriptionItem
                    label="City"
                    value={orderData.city}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("city", newValue)}
                    suggestions={comunas}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Vendedor</div>}
                >
                  <EditableDescriptionItem
                    label="seller_id"
                    value={
                      sellers.filter(
                        (item) => item.personnel_id === orderData.seller_id
                      )[0].name
                    }
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) =>
                      handleSave(
                        "seller_id",
                        sellers.filter((item) => item.name === newValue)[0]
                          .personnel_id
                      )
                    }
                    suggestions={sellers.map((item) => item.name)}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<div style={centeredLabelStyle}>Comuna</div>}
                >
                  <EditableDescriptionItem
                    label="Phone"
                    value={orderData.phone_number}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("phone_number", newValue)}
                  />
                </Descriptions.Item>
              </Descriptions>

              {orderData.order_source === "Whatsapp" && (
                <>
                  <Popover
                    placement="right"
                    title={orderData.order_source}
                    content={
                      <div>
                        {orderData.raw.split("\n").map((item) => (
                          <p>{item}</p>
                        ))}
                      </div>
                    }
                  >
                    <Button>Ver Mensaje Original</Button>
                  </Popover>
                  <Button onClick={handleCopy}>
                    {isCopied ? "Copiado!" : "Copiar Mensaje"}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <div className="bg-white rounded-lg p-4">
          <Title level={4}>Productos</Title>
          <Divider style={{ margin: "4px" }} />
          {isDataLoading ? (
            <>
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
            </>
          ) : (
            <>
              <ProductTable
                order_id={id}
                products={orderData.products}
                setProducts={(newProducts) =>
                  setOrderData((prevOrderData) => ({
                    ...prevOrderData,
                    products: newProducts,
                  }))
                }
                totalAmount={orderData.amount}
                updateTotalOrderAmount={(price) =>
                  handleSave("amount", orderData.amount + price)
                }
              />
              <div className="flex w-full justify-end mt-5 items-center gap-3">
                <div className="text-gray-500 text-md">Monto Total:</div>
                <Typography.Text strong>
                  <EditableDescriptionItem
                    label="amount"
                    value={orderData.amount}
                    isEditingDefault={isEditingDefault}
                    onSave={(newValue) => handleSave("amount", newValue)}
                    numericInput={true}
                    format={format}
                  />
                </Typography.Text>
              </div>
            </>
          )}
        </div>
        <div className="bg-white rounded-lg p-4">
          <Title level={4}>Información de Pago</Title>
          {isDataLoading ? (
            <>
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
            </>
          ) : (
            <>
              <PaymentInfoCard
                paymentInfo={orderData.payment_info_array}
                setPaymentInfo={(newPayments) =>
                  setOrderData((prevOrderData) => ({
                    ...prevOrderData,
                    payment_info_array: newPayments,
                  }))
                }
              />
            </>
          )}
        </div>
        <div className="bg-white rounded-lg p-4">
          <Title level={4}>Información de Entrega</Title>
          {isDataLoading ? (
            <>
              <Skeleton active title={false} paragraph={{ rows: 4 }} />
            </>
          ) : (
            <>
              <DeliveryInfoCard
                deliveryInfo={orderData.delivery_info_array}
                setDeliveryInfo={(newDeliveries) =>
                  setOrderData((prevOrderData) => ({
                    ...prevOrderData,
                    delivery_info_array: newDeliveries,
                  }))
                }
                orderID={id}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
