import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Popconfirm,
  Badge,
  Steps,
  Typography,
  Select,
  Option,
  message,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";

import dayjs from "dayjs";
import "dayjs/locale/es";

import { fetchDrivers } from "../utils/fetchDrivers";
import {
  fetchOrderByID,
  editDeliveryOrder,
  addDeliveryOrder,
  deleteDeliveryOrder,
} from "../utils/fetchOrders";

const { Text } = Typography;
const { Step } = Steps;

const statusMap = {
  "Pendiente de entrega": "Pendiente de entrega",
  "En Ruta": "En Ruta",
  Entregado: "Entregado",
  "No Recibido": "No Recibido",
};

const statusColors = {
  "Pendiente de entrega": "red",
  "En Ruta": "orange",
  Entregado: "green",
  "No Recibido": "gray",
};

const DeliveryInfoCard = ({ deliveryInfo, setDeliveryInfo, orderID }) => {
  // console.log("esto es deliveryinfo", deliveryInfo);
  const [loadingDeliveryInfo, setLoadingDeliveryInfo] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingDelivery, setEditingDelivery] = useState(null);
  const [form] = Form.useForm();

  const [drivers, setDrivers] = useState([]);

  const [options, setOptions] = useState([]);
  const [isDriverLoading, setIsDriverLoading] = useState(true);

  useEffect(async () => {
    dayjs.locale("es");
    const newOptions = await fetchDrivers();
    console.log(
      "options",
      newOptions.map((personnel) => {
        return {
          id: personnel.personnel_id,
          value: personnel.personnel_id,
          label: personnel.name,
        };
      })
    );
    setOptions(
      newOptions.map((personnel) => {
        return {
          id: personnel.personnel_id,
          value: personnel.personnel_id,
          label: personnel.name,
        };
      })
    );
    setIsDriverLoading(false);
  }, []);

  const handleAdd = () => {
    setEditingDelivery(null);
    form.resetFields();
    form.setFieldsValue({
      driver_id: 0,
      delivery_status: "Pendiente de entrega",
    });
    setIsModalVisible(true);
  };

  const handleEdit = (item) => {
    setEditingDelivery(item);
    console.log("item to be edited", item);
    form.setFieldsValue({
      ...item,
      driver_id: item?.driver_id || 0,
      delivery_date: item.delivery_date
        ? dayjs(item.delivery_date, "YYYY-MM-DD")
        : null,
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (delivery_id) => {
    setLoadingDeliveryInfo(delivery_id);
    try {
      const val = await deleteDeliveryOrder(delivery_id);
      console.log("val de delete", val);
      const newDeliveryInfo = await fetchOrderByID(orderID);
      setDeliveryInfo(newDeliveryInfo[0].delivery_info_array);
    } catch (error) {
      console.log(error);
      message.error("Error al eliminar la entrega.");
    } finally {
      setLoadingDeliveryInfo(false);
    }
  };

  const [loadingModalOkButton, setLoadingModalOkButton] = useState(false);

  const handleModalOk = async () => {
    setLoadingModalOkButton(true);
    setLoadingDeliveryInfo(true);
    let newDeliveryInfo = [...deliveryInfo];
    try {
      const values = await form.validateFields();
      console.log("Received values of form: ", values);
      // console.log("this is new delivery info", newDeliveryInfo);
      if (editingDelivery) {
        // Editing a delivery
        console.log("this is editing delivery", editingDelivery);
        const index = newDeliveryInfo.findIndex(
          (item) => item.delivery_id === editingDelivery.delivery_id
        );
        newDeliveryInfo[index] = {
          ...values,
          delivery_date: values.delivery_date.format("YYYY-MM-DD"),
          delivery_id: editingDelivery.delivery_id,
        };
        console.log("this is new delivery info index", newDeliveryInfo[index]);
        const val = await editDeliveryOrder(newDeliveryInfo[index]);
        // console.log(val);
        setDeliveryInfo(newDeliveryInfo);
        message.success("Entrega actualizada correctamente.");
        newDeliveryInfo = await fetchOrderByID(orderID);
        setDeliveryInfo(newDeliveryInfo[0].delivery_info_array);
      } else {
        // Adding a delivery
        // newDeliveryInfo.push({
        //   ...values,
        //   delivery_date: values.delivery_date.format("YYYY-MM-DD"),
        //   // delivery_id: Date.now(),
        // });
        // setDeliveryInfo(newDeliveryInfo);
        const postData = { ...values, order_id: orderID };
        console.log("estos son los valores", postData);
        const val = await addDeliveryOrder(postData);
        console.log("esto es val de created", val);
        message.success("Entrega actualizada correctamente.");
        newDeliveryInfo = await fetchOrderByID(orderID);
        setDeliveryInfo(newDeliveryInfo[0].delivery_info_array);
      }
      // console.log("this is the new delivery info", newDeliveryInfo);
    } catch (err) {
      message.error("Error al actualizar la entrega.");
      console.log("error", err);
    } finally {
      setLoadingModalOkButton(false);
      setIsModalVisible(false);
      setLoadingDeliveryInfo(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={deliveryInfo}
        renderItem={(item) => {
          const currentStatus = item.delivery_status !== undefined ? item : 0;
          const isGray = item.delivery_status === 3;
          // console.log("currentStatus", currentStatus);
          return (
            <List.Item>
              <Card
                title={
                  <span style={{ color: isGray ? "gray" : "inherit" }}>
                    Fecha de entrega: {moment(item.delivery_date).format("LL")}
                  </span>
                }
                extra={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Badge
                      status="default"
                      color={statusColors[currentStatus]}
                      text={item.delivery_status}
                    />
                    <Button
                      disabled={loadingDeliveryInfo}
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => handleEdit(item)}
                      style={{
                        marginLeft: 8,
                      }}
                    />
                    <Popconfirm
                      title={
                        <div className="flex flex-col">
                          <div>Esta acción no se puede deshacer.</div>
                          <div>¿Deseas continuar?</div>
                        </div>
                      }
                      onConfirm={() => handleDelete(item.delivery_id)}
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                          marginLeft: 8,
                        }}
                        disabled={
                          deliveryInfo.length === 1 || loadingDeliveryInfo
                        }
                      />
                    </Popconfirm>
                  </div>
                }
              >
                <div
                  className="md:grid md:grid-cols-2 mb-4 flex-columns"
                  style={{ color: isGray ? "gray" : "inherit" }}
                >
                  <div>
                    <Text strong>Conductor: </Text>
                    {isDriverLoading ? (
                      <span className="text-gray-500">Cargando...</span>
                    ) : (
                      options.filter(
                        (driver) => driver.id === item.driver_id
                      )[0].label || <span className="italic">No asignado</span>
                    )}
                  </div>
                  <div>
                    <Text strong>Observacion:</Text>{" "}
                    {item.delivery_note || (
                      <span className="italic">Sin Nota</span>
                    )}
                  </div>
                </div>
                <Steps current={currentStatus} size="small">
                  <Step
                    title={
                      <span
                        className={
                          item.delivery_status === "No Recibido" &&
                          "italic text-gray-500"
                        }
                      >
                        No Asignado
                      </span>
                    }
                  />
                  <Step
                    title={
                      <span
                        className={
                          item.delivery_status === "No Recibido" &&
                          "italic text-gray-500"
                        }
                      >
                        En Ruta
                      </span>
                    }
                  />
                  <Step
                    title={
                      item.delivery_status === "No Recibido" ? (
                        <span className="italic text-gray-500">
                          No Recibido
                        </span>
                      ) : (
                        "Entregado"
                      )
                    }
                    icon={
                      item.delivery_status === "No Recibido" ? (
                        <CloseCircleOutlined
                          style={{ color: "gray" }}
                          className="w-6 h-6"
                        />
                      ) : null
                    }
                  />
                </Steps>
              </Card>
            </List.Item>
          );
        }}
      />
      <Button
        type="dashed"
        onClick={handleAdd}
        icon={<PlusOutlined />}
        style={{ width: "100%", marginTop: 16 }}
      >
        Añadir Entrega
      </Button>
      <Modal
        title={editingDelivery ? "Editar Entrega" : "Añadir Delivery"}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={editingDelivery ? "Guardar" : "Añadir"}
        confirmLoading={loadingModalOkButton}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ driver_id: deliveryInfo[0].driver_id }}
        >
          <Form.Item
            name="delivery_date"
            label="Fecha de Entrega"
            rules={[
              {
                required: true,
                message: "Debes ingresar una fecha de entrega!",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="delivery_status"
            label="Estado de la entrega"
            rules={[
              {
                required: true,
                message: "Debes ingresar un Estado del Pedido!",
              },
            ]}
          >
            <Select>
              {Object.keys(statusMap).map((key) => (
                <Select.Option key={statusMap[key]} value={statusMap[key]}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="driver_id" label="Chofer">
            <Select
              style={{ width: 150 }}
              // onChange={onChange}
            >
              {options.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="delivery_note" label="Nota de Entrega">
            <Input autoComplete="off" onPressEnter={handleModalOk} />
          </Form.Item>

          <Button type="primary" htmlType="submit" className="hidden">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default DeliveryInfoCard;
