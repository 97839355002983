import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Header, InDevelopmentPage } from "../components";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  RightOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Card,
  Row,
  Col,
  message,
  Spin,
  Badge,
  Select,
  List,
  Button,
  Divider,
} from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  getLastDays,
  getDayOfWeekInSpanish,
  getLocaleDateString,
} from "../utils/utils";
import api from "../api/axios";
import { getTotalSalesByDay } from "../utils/fetchHome";
import { fetchOrders } from "../utils/fetchOrders";

import { CustomCard } from "../components";
import { ListItem } from "@mui/material";

import { fetchProducts } from "../utils/fetchProducts";

const { Text } = Typography;

const { format } = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const getRatioRevenue = (todayRevenue, yesterdayRevenue) => {
  const ratio = todayRevenue / yesterdayRevenue - 1;
  const ratioString = Math.trunc(ratio * 100, 2) + "%";
  const backgroundColor = ratio > 0 ? "#52c41a" : "";
  return { ratio, ratioString, backgroundColor };
};

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalSalesWeek, setTotalSalesWeek] = useState([]);
  const [loadingTotalSalesWeek, setLoadingTotalSalesWeek] = useState(true);
  const [maxTotalPerDay, setMaxTotalPerDay] = useState(0);
  const [ordersToday, setOrdersToday] = useState([]);
  const [totalRevenueToday, setTotalRevenueToday] = useState(0);
  const [ordersLastWeek, setOrdersLastWeek] = useState([]);
  const [totalRevenueLastWeek, setTotalRevenueLastWeek] = useState(0);
  const [ratioRevenueTodayLastWeek, setRatioRevenueTodayLastWeek] = useState(0);
  const [ordersTomorrow, setOrdersTomorrow] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [totalSalesMonth, setTotalSalesMonth] = useState();
  const [graphTimeFrameSelection, setGraphTimeFrameSelection] =
    useState("week");
  const [countbySellerTomorrow, setCountBySellerTomorrow] = useState([]);
  const [topSellerSelect, setTopSellerSelect] = useState(0);
  const [topSellerSelectCount, setTopSellerSelectCount] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [products, setProducts] = useState([]);
  const [ratioOrdersNumber, setRatioOrdersNumber] = useState(0);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const today = new Date();
  const tomorrow = new Date().setDate(today.getDate() + 1);
  const lastweek = new Date().setDate(today.getDate() - 7);

  useEffect(async () => {
    // console.log("token access", localStorage.getItem("token"));
    setLoadingTotalSalesWeek(true);
    const dates = getLastDays(today, 7).map((date) =>
      getLocaleDateString(date)
    );
    console.log("estos son dates", dates);
    try {
      const newTotalSalesWeek = [];
      for (let i = 0; i < dates.length - 1; i++) {
        const newDay = dates[i + 1];
        const newSalesDay = await getTotalSalesByDay(newDay);
        newTotalSalesWeek.push({
          date: newDay,
          count: newSalesDay.data[0].count,
        });
      }
      // Total Weeks Sales
      setTotalSalesWeek(newTotalSalesWeek);
      const maxValue = Math.max(...newTotalSalesWeek.map((item) => item.count));
      const roundedMaxValue = (Math.trunc(maxValue / 100) + 1) * 100;
      setMaxTotalPerDay(roundedMaxValue);
      setLoadingTotalSalesWeek(false);
      setGraphData(newTotalSalesWeek);

      // Today's Sales
      const newOrders = await fetchOrders(getLocaleDateString(today), null);
      setOrdersToday(newOrders);
      const totalRevenue = newOrders.reduce(
        (sum, current) => sum + current.amount,
        0
      );
      setTotalRevenueToday(totalRevenue);

      // Last Week's Sales
      const newOrdersLastWeek = await fetchOrders(
        getLocaleDateString(lastweek),
        null
      );
      setOrdersLastWeek(newOrdersLastWeek);
      const totalRevenueLastWeek = newOrdersLastWeek.reduce(
        (sum, current) => sum + current.amount,
        0
      );
      setTotalRevenueLastWeek(totalRevenueLastWeek);

      // Get the Ratios
      const ratioObject = getRatioRevenue(totalRevenue, totalRevenueLastWeek);
      setRatioRevenueTodayLastWeek(ratioObject);
      const ratioObjectOrdersNumber = getRatioRevenue(
        newOrders.length,
        newOrdersLastWeek.length
      );
      setRatioOrdersNumber(ratioObjectOrdersNumber);

      // Tomorrows Sales
      const newOrdersTomorrow = await fetchOrders(
        null,
        getLocaleDateString(tomorrow)
      );
      setOrdersTomorrow(newOrdersTomorrow);

      // Count by Seller
      const counts = {};
      newOrdersTomorrow.forEach((item) => {
        counts[item.seller_name] = (counts[item.seller_name] || 0) + 1;
      });
      let arrCounts = [];
      for (var seller in counts) {
        arrCounts.push({ seller: seller, count: counts[seller] });
      }
      setCountBySellerTomorrow(arrCounts.sort((a, b) => b.count - a.count));
      setTopSellerSelect(arrCounts[0].seller);
      setTopSellerSelectCount(arrCounts[0].count);

      // Fetch Products
      const newProducts = await fetchProducts();
      setProducts(newProducts);

      // Fetch Monthly Sales
      const datesMonth = getLastDays(today, 30).map((date) =>
        getLocaleDateString(date)
      );
      const newTotalSalesMonth = [];
      for (let i = 0; i < datesMonth.length - 1; i++) {
        const newDay = datesMonth[i + 1];
        const newSalesDay = await getTotalSalesByDay(newDay);
        newTotalSalesMonth.push({
          date: newDay,
          count: newSalesDay.data[0].count,
        });
      }
      setTotalSalesMonth(newTotalSalesMonth);
    } catch (error) {
      message.error("Error al cargar los datos. Intente nuevamente.");
      console.log("error", error);
    } finally {
    }
  }, []);

  return (
    <>
      <div className="">
        {/* Showing data saved correctly */}
        <div className="mb-5 flex justify-between items-center">
          <div className="text-3xl font-bold text-gray-400">Hola de nuevo!</div>
          <div className="flex space-x-2 items-center">
            <div className="flex flex-col justify-end text-end text-sm">
              <div className="text-gray-400">Fecha de hoy</div>
              <div>{getLocaleDateString(today)}</div>
            </div>
            <Button>
              <SyncOutlined />
            </Button>
          </div>
        </div>
        <div>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <div className="py-3">
                <div className="font-bold text-xl p-1">Ingresos de hoy</div>
                <div className="text-3xl font-bold">
                  {format(totalRevenueToday)}{" "}
                  <Badge
                    count={ratioRevenueTodayLastWeek.ratioString}
                    style={{
                      backgroundColor:
                        ratioRevenueTodayLastWeek.backgroundColor,
                    }}
                  />
                  <Badge
                    className="ml-1"
                    count={format(totalRevenueToday - totalRevenueLastWeek)}
                    style={{
                      backgroundColor:
                        ratioRevenueTodayLastWeek.backgroundColor,
                    }}
                  />
                </div>
                <div className="">
                  vs. {format(totalRevenueLastWeek)} (
                  {getDayOfWeekInSpanish(today)} pasado)
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="py-3">
                <div className="font-bold text-xl p-1">Pedidos de hoy</div>
                <div className="text-3xl font-bold">
                  {ordersToday.length}{" "}
                  <Badge
                    count={ratioOrdersNumber.ratioString}
                    style={{
                      backgroundColor: ratioOrdersNumber.backgroundColor,
                    }}
                  />
                  <Badge
                    className="ml-1"
                    count={ordersToday.length - ordersLastWeek.length}
                    style={{
                      backgroundColor: ratioOrdersNumber.backgroundColor,
                    }}
                  />
                </div>
                <div className="">
                  vs. {ordersLastWeek.length} ({getDayOfWeekInSpanish(today)}{" "}
                  pasado)
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <CustomCard
                title={`Entrega mañana`}
                footer={
                  <Link
                    className="text-sm p-0"
                    to={`/orders?deliveryDate=${getLocaleDateString(tomorrow)}`}
                  >
                    <RightOutlined /> Ver Pedidos
                  </Link>
                }
              >
                {ordersTomorrow.length}
              </CustomCard>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              {/* <div className="text-xl font-bold">Ventas por Vendedor</div> */}
              <CustomCard
                title={`Top Vendedores`}
                footer={
                  <div>
                    {countbySellerTomorrow[0] ? (
                      <Select
                        className="w-full mt-1"
                        size="small"
                        value={topSellerSelect}
                        onChange={(value) => {
                          setTopSellerSelect(value);
                          setTopSellerSelectCount(
                            countbySellerTomorrow.find(
                              (item) => item.seller === value
                            ).count
                          );
                        }}
                        options={countbySellerTomorrow.map((item) => {
                          return { value: item.seller, label: item.seller };
                        })}
                      ></Select>
                    ) : (
                      <span className="italic">Cargando...</span>
                    )}
                  </div>
                }
              >
                {topSellerSelectCount}
              </CustomCard>
              {/* <List>
                {countbySellerTomorrow.map((item) => (
                  <ListItem className="outline my-2 shadow-md rounded-xl outline-1 outline-gray-200">
                    {item.seller}: {item.count}
                  </ListItem>
                ))}
              </List> */}
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={4}>
              <CustomCard
                title={"Productos"}
                footer={
                  <Link className="text-sm p-0" to={"/products"}>
                    <RightOutlined /> Ver Productos
                  </Link>
                }
              >
                {products.length}
              </CustomCard>
            </Col>
            <Col span={24}>
              <Card
                className="m-0 p-0"
                title={
                  <div className=" sm:flex w-full sm:justify-between py-2">
                    <div className="sm:text-normal">
                      Pedidos agendados por día
                    </div>
                    <Select
                      size="small"
                      className="min-w-24"
                      options={[
                        { value: "week", label: "Semana" },
                        { value: "month", label: "Mes" },
                      ]}
                      defaultValue={"week"}
                      onSelect={(value) => {
                        if (value === "week") {
                          setGraphData(totalSalesWeek);
                          const maxValue = Math.max(
                            ...totalSalesWeek.map((item) => item.count)
                          );
                          const roundedMaxValue =
                            (Math.trunc(maxValue / 100) + 1) * 100;
                          setMaxTotalPerDay(roundedMaxValue);
                        } else {
                          setGraphData(totalSalesMonth);
                          const maxValue = Math.max(
                            ...totalSalesMonth.map((item) => item.count)
                          );
                          const roundedMaxValue =
                            (Math.trunc(maxValue / 100) + 1) * 100;
                          setMaxTotalPerDay(roundedMaxValue);
                        }
                      }}
                    />
                  </div>
                }
                // bordered={false}
                hoverable
              >
                <Spin spinning={loadingTotalSalesWeek}>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={graphData}>
                      <CartesianGrid strokeDasharray="4 4 " />
                      <XAxis
                        dataKey="date"
                        tick={{ fontSize: 10, fill: "#8884d8" }}
                        angle={-45} // Rotate the labels by -45 degrees
                        textAnchor="end" // Align text to the end
                        height={60} // Adjust height to accommodate the rotated labels
                        dy={10} // Adjust vertical offset to prevent overlap
                      />
                      <YAxis domain={[0, maxTotalPerDay]} />
                      <Tooltip />
                      {/* <Legend /> */}
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#4048F1"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Spin>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Home;
