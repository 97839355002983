// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }) => {
  const token = !!localStorage.getItem("token");
  // console.log("este es el token que tengo", localStorage.getItem("token"));
  if (!token) {
    return <Navigate to="/login" />;
  }
  return element;
};

export default PrivateRoute;
