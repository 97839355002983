import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Header,
  GeneralInformation,
  PricesProducts,
  VariantsProducts,
} from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { useParams } from "react-router-dom";
import { ThreeDot } from "react-loading-indicators";
import { message, Card, Button, Popconfirm } from "antd";

import {
  fetchProductsById,
  fetchProductPricesById,
  getVariantByProductID,
  addVariant,
  deleteVariant,
  deleteProduct,
  // saveProductField,
} from "../utils/fetchProducts";

import { groupPrices } from "../utils/utils";
import { updateLocale } from "moment";
import WebProducts from "../components/ProductDetailsComponents/WebProducts";

const Product = () => {
  // const { loadingMessage, setLoadingMessage } = useStateContext();
  const { product_id } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);
  const [data, setData] = useState({});
  const [prices, setPrices] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [mappedProductsWeb, setMappedProductsWeb] = useState([]);

  const [variants, setVariants] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // setLoadingMessage(true);
    setIsDataLoading(true);
    setDataError(null);
    const fetchData = async () => {
      try {
        const vals = await fetchProductsById(Number(product_id));
        setData(vals);
        const prices = await fetchProductPricesById(Number(product_id));
        const pricesSorted = prices.sort((a, b) => a.quantity - b.quantity);
        setPrices(pricesSorted);
        const variants = await getVariantByProductID(product_id);
        setVariants(variants);
        setIsDataLoading(false);
        // setLoadingMessage(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setDataError(error);
        setIsDataLoading(false);
        // setLoadingMessage(false);
      }
    };
    fetchData();
  }, [refreshPage]);

  const handleSaveGeneralInfo = async (updatedData) => {
    // message.success(`Product information saved successfully!`);
    setData(updatedData);
    // try {
    //   await saveProductField(product_id, updatedData);
    //   setData(updatedData);
    //   message.success(`Product information saved successfully!`);
    // } catch (error) {
    //   message.error(`Failed to save product information: ${error.message}`);
    // }
  };

  const requestVariants = async () => {
    console.log("haciendo un nuevo request de variantes");
    try {
      const variants = await getVariantByProductID(product_id);
      console.log("variants", variants);
      setVariants(variants);
    } catch (error) {
      console.error("Failed to fetch variants:", error);
    } finally {
      setLoadingVariants(false);
    }
  };

  const handleAddVariant = async (newVariantData) => {
    try {
      const val = await addVariant(product_id, newVariantData);
      console.log("val", val);
      message.success("Nueva variante agregada correctamente.");
      requestVariants();
    } catch (error) {
      message.error(
        `Error al agregar la variante. Recargue la página e intente nuevamente.`
      );
    } finally {
      setLoadingVariants(false);
    }
  };

  const handleDeleteVariant = async (variant) => {
    try {
      const val = await deleteVariant(variant);
      console.log("val", val);
      message.success("Variante eliminada correctamente.");
      requestVariants();
    } catch (error) {
      message.error(
        `Error al eliminar la variante. Recargue la página e intente nuevamente.`
      );
    } finally {
      setLoadingVariants(false);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const val = await deleteProduct(product_id);
      console.log("val from delete", val);
      message.success("Producto eliminado correctamente.");
      navigate("/products");
    } catch (error) {
      message.error("Error al eliminar el producto.");
    }
  };

  return (
    <>
      <div className="bg-white rounded-3xl">
        <div className="">
          {isDataLoading ? (
            <div className="w-full text-center min-h-72 flex items-center justify-center">
              <ThreeDot
                variant="brick-stack"
                color="#4048F1"
                size="medium"
                text=""
                textColor=""
              />
            </div>
          ) : dataError ? (
            <div>No se pudo cargar los productos.</div>
          ) : (
            <div className="w-full flex flex-col lg:grid lg:grid-cols-4 lg:gap-x-4 gap-y-4 mb-6 ">
              <Card
                title={
                  <div className="w-full flex justify-between">
                    <div>Información General</div>
                    <div>
                      <Popconfirm
                        title="Confirmar acción"
                        description="Esta acción no se puede deshacer."
                        onConfirm={handleDeleteProduct}
                        // onCancel={cancel}
                      >
                        <Button type="text" danger>
                          Eliminar Producto
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                }
                className="lg:col-span-3"
              >
                <GeneralInformation
                  data={data}
                  onSave={handleSaveGeneralInfo}
                />
              </Card>
              <Card className="lg:col-span-1" title="Conexión Páginas Web">
                <WebProducts productID={product_id} />
              </Card>
              <div className="flex flex-col lg:grid-cols-2 lg:flex-row lg:gap-x-4 col-span-5">
                <Card title="Variantes" className="w-full lg:col-span-1">
                  <VariantsProducts
                    variants={variants}
                    setVariants={setVariants}
                    requestVariants={() => requestVariants()}
                    loadingState={{ loadingVariants, setLoadingVariants }}
                    addVariantCallback={async (newVariantData) => {
                      await handleAddVariant(newVariantData);
                    }}
                    deleteVariantCallback={async (variant) => {
                      await handleDeleteVariant(variant);
                    }}
                  />
                </Card>

                <Card title="Precios" className="w-full lg:col-span-1">
                  <PricesProducts
                    prices={prices}
                    setPrices={setPrices}
                    productID={product_id}
                  />
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
