import React from "react";
import {
  getMappedProductsWeb,
  addProductsWeb,
  deleteProductsWeb,
} from "../../utils/fetchProducts";
import { useState, useEffect } from "react";
import { Skeleton } from "antd";
import {
  Button,
  Card,
  Popconfirm,
  Input,
  InputNumber,
  Form,
  message,
} from "antd";
import {
  // EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const WebProducts = ({ productID }) => {
  const [mappedWebProducts, setMappedWebProducts] = useState([]);
  const [showAddWebProduct, setShowAddWebProduct] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [updateWebProductMapping, setUpdateWebProductMapping] = useState(true);

  const toggleShowAddWebProduct = () => {
    setShowAddWebProduct((current) => setShowAddWebProduct(!current));
  };

  const handleAddWebProduct = async (values) => {
    console.log(values);
    setEditLoading(true);
    const formData =
      values["format"] === ""
        ? { ...values, product_id: Number(productID), format: "Desconocido" }
        : { ...values, product_id: Number(productID) };
    setEditLoading(true);
    try {
      const val = await addProductsWeb(formData);
      message.success("Mapeo de producto creado correctamente!");
      setUpdateWebProductMapping(!updateWebProductMapping);
      console.log("información refrescada");
    } catch (error) {
      console.log("Error al agregar producto web:", error);
    } finally {
      setShowAddWebProduct(false);
      setEditLoading(false);
    }
    // Add new web product logic here
  };

  const handleDelete = async (wbm) => {
    setEditLoading(true);
    const formData = {
      format: wbm.format,
      name: wbm.product,
      quantity: wbm.quantity,
      product_id: Number(productID),
    };
    try {
      const val = await deleteProductsWeb(formData);
      setUpdateWebProductMapping(!updateWebProductMapping);
      message.success("Mapeo de producto eliminado correctamente!");
    } catch (error) {
      console.log("Error al eliminar producto web:", error);
      message.error("Error, intente nuevamente.");
    } finally {
      setEditLoading(false);
    }
  };

  useEffect(() => {
    setEditLoading(true);
    const getInfo = async (productID) => {
      try {
        const response = await getMappedProductsWeb(productID);
        setMappedWebProducts(response);
      } catch (error) {
        setMappedWebProducts("error");
      } finally {
        setEditLoading(false);
      }
    };
    getInfo(productID);
  }, [updateWebProductMapping]);
  return (
    <div className="overflow-y-scroll max-h-96">
      <div>
        {mappedWebProducts ? (
          mappedWebProducts === "error" ? (
            <div>Error al cargar los productos</div>
          ) : (
            <div>
              {showAddWebProduct ? (
                <Card size="small" className="mb-4">
                  <div className="flex items-center justify-between"></div>
                  <div>
                    <Form
                      onFinish={handleAddWebProduct}
                      //   onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="flex flex-col gap-y-1"
                      disabled={editLoading}
                    >
                      <Form.Item
                        name="name"
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar un nombre",
                          },
                        ]}
                      >
                        <Input placeholder="Producto" />
                      </Form.Item>
                      <Form.Item name="format" className="m-0">
                        <Input placeholder="Formato" />
                      </Form.Item>
                      <Form.Item
                        name="quantity"
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar una cantidad",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Cantidad"
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item label={null} className="m-0">
                        <div className="flex justify-end gap-x-1">
                          <Button
                            onClick={() => {
                              toggleShowAddWebProduct();
                            }}
                            size="small"
                          >
                            Cancelar
                          </Button>
                          <Button type="primary" htmlType="submit" size="small">
                            Agregar
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </Card>
              ) : (
                <Button
                  type="dashed"
                  className="w-full h-10 hover:h-16 transition-all duration-100 ease-in-out mb-4"
                  onClick={toggleShowAddWebProduct}
                >
                  Agregar Nombre
                </Button>
              )}
              {mappedWebProducts.map((product, index) => (
                <Card key={index} size="small mb-2">
                  <div className="w-full">
                    <div className="flex justify-between">
                      <div
                        className={`font-medium text-wrap ${
                          editLoading && "text-gray-500"
                        }`}
                      >
                        {product.product}{" "}
                      </div>
                      <Popconfirm
                        title={
                          <div className="flex flex-col">
                            <div>Esta acción no se puede deshacer.</div>
                            <div>¿Deseas continuar?</div>
                          </div>
                        }
                        onConfirm={() => handleDelete(product)}
                      >
                        <Button
                          type="text"
                          danger
                          className=" p-1"
                          disabled={editLoading}
                          loading={editLoading}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </div>
                    <div className="w-full flex justify-between text-sm text-gray-500">
                      <div className="italic">{product.format}</div>
                      <div>
                        Cantidad:{" "}
                        <span className="font-bold">{product.quantity}</span>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )
        ) : (
          <Skeleton active />
        )}
      </div>
    </div>
  );
};

export default WebProducts;
