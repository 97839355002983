import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  InputNumber,
  Button,
  Popconfirm,
  Form,
  AutoComplete,
  List,
  Card,
  message,
  Modal,
  Select,
  Checkbox,
  Tag,
  Radio,
  Space,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import { fetchProducts, fetchProductsById } from "../utils/fetchProducts";

import {
  addOrderProduct,
  deleteOrderProduct,
  fetchOrderByID,
} from "../utils/fetchOrders";

import { all } from "axios";

import { getStockProducts } from "../utils/utils";

const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    // handleSave(); // Make something here to save the value
  }
};

const { format } = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  suggestions,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber min={1} />
    ) : suggestions?.length > 0 ? (
      <AutoComplete
        options={suggestions.map((suggestion) => ({ value: suggestion }))}
        onKeyPress={handleKeyPress}
        style={{ width: "100%" }}
      >
        <Input />
      </AutoComplete>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProductTable = ({
  order_id,
  products,
  setProducts,
  totalAmount,
  updateTotalOrderAmount,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm(); // adding a product
  const [allProducts, setAllProducts] = useState([]);
  const [loadingVariants, setLoadingVariants] = useState(true);
  const [allVariants, setAllVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [hasOffer, setHasOffer] = useState(false);
  const [allPrices, setAllPrices] = useState([]);
  const [checkBoxAddProduct, setCheckBoxAddProduct] = useState(true);
  const [priceAddProduct, setPriceAddProduct] = useState(0);
  const [loadingSubmitAddProductForm, setLoadingSubmitAddProductForm] =
    useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingStock, setLoadingStock] = useState(true);
  const [stockProducts, setStockProducts] = useState([]);

  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState([]);
  const [suggestedTotalPrice, setSuggestedTotalPrice] = useState(null);

  useEffect(async () => {
    setLoadingStock(true);
    try {
      const val = await fetchProducts();
      setData(products);
      setAllProducts(val);
      const newStockProducts = getStockProducts(products, val);
      setStockProducts(newStockProducts);
    } catch (error) {
      message.error("No se han podido cargar los productos.");
    } finally {
      setLoadingStock(false);
    }
  }, []);

  useEffect(() => {
    setData(products);
    const newStockProducts = getStockProducts(products, allProducts);
    setStockProducts(newStockProducts);
  }, [products]);

  const isEditing = (record) => record.order_product_id === editingKey;

  // Changing the existing products
  const handleEditProduct = (key) => {
    setEditingKey(key);
  };

  const changeQuantityToProduct = (index, boolean) => {
    let newData = [...data];
    const currentQuantity = newData[index].quantity;
    const addValue = boolean ? 1 : -1;
    if (currentQuantity === 1 && addValue === -1) {
      message.warning("El valor mínimo es 1.");
    } else {
      newData[index].quantity = newData[index].quantity + addValue;
      setData(newData);
      setProducts(newData);
    }
  };

  // Adding a product
  const showModal = async () => {
    setIsModalVisible(true);
    try {
      const val = await fetchProducts();
      setAllProducts(val);
    } catch (error) {
      message.error("No se han podido cargar los productos.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAllVariants([]);
    setSelectedVariant(null);
    setHasOffer(false);
    setAllPrices([]);
    setPriceAddProduct(0);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    console.log("Form values:", values);
    setLoadingSubmitAddProductForm(true);
    try {
      const val = await addOrderProduct(order_id, values);
      await updateTotalOrderAmount(values.price);
      const newProductsInformation = await fetchOrderByID(order_id);
      setProducts(newProductsInformation[0].products);
      message.success("Producto añadido correctamente!");
    } catch (error) {
      message.error("Error al añadir el producto.");
      console.log("error", error);
    } finally {
      setLoadingSubmitAddProductForm(false);
      setIsModalVisible(false);
      setAllVariants([]);
      setSelectedVariant(null);
      setHasOffer(false);
      setAllPrices([]);
      setPriceAddProduct(0);
      form.resetFields();
    }
  };

  const edit = (record) => {
    form.setFieldsValue({
      product_name: "",
      variant: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record.order_product_id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.order_product_id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setProducts(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setProducts(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = async (key) => {
    const orderProductID = products[key].order_product_id;
    console.log("orderProductID:", orderProductID);
    setLoadingProducts(true);
    try {
      const val = await deleteOrderProduct(orderProductID);
      console.log("val");
      await updateTotalOrderAmount(-products[key].price);
      const newProductsInformation = await fetchOrderByID(order_id);
      setProducts(newProductsInformation[0].products);
      setData(newProductsInformation[0].products);
      message.success("Producto eliminado correctamente.");
    } catch (error) {
      message.error("Error al eliminar el producto.");
      console.log("error", error);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleAdd = () => {
    const newProduct = {
      status: "found",
      quantity: 1,
      not_found_name: null,
      product_name: "New Product",
      variant: "New Variant",
      not_found_variant: null,
    };
    setData([...data, newProduct]);
    setProducts([...data, newProduct]);
    edit(newProduct);
  };

  const columns = [
    {
      title: "id",
      dataIndex: "order_product_id",
      editable: false,
    },
    {
      title: "Producto",
      dataIndex: "product_name",
      width: 150,
      editable: true,
    },
    {
      title: "Variante",
      dataIndex: "variant",
      width: 100,
      editable: true,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: 75,
      editable: true,
      //   render: (text) => <InputNumber min={1} defaultValue={text} />,
    },
    {
      title: "Precio Total",
      dataIndex: "price",
      editable: true,
      render: (amount) => format(amount),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.order_product_id)}
              style={{ marginRight: 4 }}
            >
              <SaveOutlined type="link" />
            </Button>
            {/* <Popconfirm
              title="Confirmar acción"
              onConfirm={(record) => {
                console.log("record id", record);
                
              }}
            > */}
            <Button onClick={cancel}>
              <CloseOutlined type="link" />
            </Button>
            {/* </Popconfirm> */}
          </span>
        ) : (
          <span>
            <Button
              disabled={editingKey !== ""}
              onClick={() => {
                console.log("editing record", record);
                edit(record);
              }}
              style={{ marginRight: 8 }}
            >
              <EditOutlined type="link" />
            </Button>
            <Popconfirm
              title="Confirmar acción"
              onConfirm={() => handleDelete(record.order_product_id)}
            >
              <Button disabled={editingKey !== ""}>
                <DeleteOutlined type="link" />
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <List>
        {data.map((product, index) => (
          <Card className="my-2">
            <div className="flex justify-between">
              <div className="flex flex-col ">
                <div className="">
                  {product.not_found_name || product.product_name}
                </div>
                <div className="text-slate-700 text-xs">
                  {product.not_found_variant || product.variant}
                </div>
                {!product.product_id || !product.variant_id ? (
                  <div>hola</div>
                ) : (
                  <div>
                    {loadingStock ? (
                      <Tag icon={<SyncOutlined spin />} color="processing">
                        Cargando
                      </Tag>
                    ) : stockProducts[index] ? (
                      <Tag color="green">Stock</Tag>
                    ) : (
                      <Tag color="red">Sin Stock</Tag>
                    )}
                  </div>
                )}
              </div>

              <div className="flex items-center">
                <div className="flex flex-col justify-end items-end mx-3 ">
                  <div className="items-center">x {product.quantity}</div>
                  <div className="text-slate-700 text-xs">
                    {format(product.price)}
                  </div>
                </div>
                <div className="">
                  {editingKey === index ? (
                    <>
                      <div className="flex justify-center items-center space-x-1">
                        <Button
                          type="text"
                          className="p-0 w-8 h-8"
                          onClick={() => {
                            changeQuantityToProduct(index, true);
                          }}
                        >
                          +
                        </Button>

                        <Button
                          type="text"
                          className="p-0 w-8 h-8"
                          onClick={() => {
                            changeQuantityToProduct(index, false);
                          }}
                        >
                          -
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <Button
                        type="link"
                        className="p-0 w-8 h-8"
                        onClick={() => {
                          handleEditProduct(index);
                        }}
                      >
                        <EditOutlined />
                      </Button> */}
                      <Popconfirm
                        title="Confirmar acción"
                        onConfirm={() => handleDelete(index)}
                      >
                        <Button
                          type="link"
                          className="p-0 w-8 h-8"
                          disabled={products?.length <= 1}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </div>
            </div>
            {editingKey === index && (
              <div className="w-full flex justify-between mt-4">
                <Button
                  size="small"
                  type="default"
                  onClick={() => {
                    setEditingKey("");
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    // handleSave(index);
                  }}
                >
                  Guardar
                </Button>
              </div>
            )}
          </Card>
        ))}
      </List>
      <Button
        type="dashed"
        onClick={showModal}
        className="mt-6 w-full"
        loading={loadingSubmitAddProductForm}
      >
        <PlusOutlined /> Agregar Producto
      </Button>
      <Modal
        title="Añadir Producto"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Producto"
            name="product_id"
            rules={[{ required: true, message: "Ingresa un producto." }]}
          >
            <Select
              options={allProducts.map((item) => {
                return { value: item.product_id, label: item.name };
              })}
              showSearch
              placeholder="Selecciona un producto"
              optionFilterProp="label"
              onSelect={async () => {
                form.setFieldsValue({ variant_id: undefined });
                console.log("esto es el forms value", form.getFieldsValue());
                const { product_id } = form.getFieldsValue();
                setAllVariants([]);
                setSelectedVariant(null);
                setHasOffer(false);
                setLoadingVariants(true);
                form.resetFields(["quantity"]);
                form.resetFields(["variant_id"]);
                try {
                  const val = await fetchProductsById(product_id);
                  setHasOffer(val.has_offer);
                  setAllVariants(val.variants_array);
                  setAllPrices(val.prices_array);
                  setPriceAddProduct(0);
                } catch (error) {
                  message.error("Error al cargar las variantes.");
                } finally {
                  setLoadingVariants(false);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Variante"
            name="variant_id"
            rules={[{ required: true, message: "Ingresa una variante." }]}
          >
            {allVariants.length > 0 ? (
              <Radio.Group onChange={(value) => setSelectedVariant(value)}>
                <Space direction="vertical">
                  {allVariants.map((item) => (
                    <Radio.Button value={item.id} className="px-2">
                      <span className="mr-2">{item.variant}</span>
                      {item.stock ? (
                        <Tag color="green">Stock</Tag>
                      ) : (
                        <Tag color="red">Sin Stock</Tag>
                      )}
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            ) : (
              <div className="italic text-xs">
                Selecciona un producto para cargar las variantes.
              </div>
            )}
            {/* <Select
              disabled={allVariants.length < 1}
              loading={loadingVariants}
              options={allVariants.map((item) => {
                return { value: item.id, label: item.variant };
              })}
              onChange={(value) => setSelectedVariant(value)}
            />
            <div className="p-2">
              {!selectedVariant ? (
                <div></div>
              ) : allVariants.find(
                  (variant) => variant.id === form.getFieldValue("variant_id")
                )?.stock ? (
                <Tag color="green">Stock</Tag>
              ) : (
                <Tag color="red">Sin Stock</Tag>
              )}
            </div> */}
          </Form.Item>
          <Form.Item
            label="Cantidad"
            name="quantity"
            rules={[{ required: true, message: "Ingresa una cantidad." }]}
          >
            <InputNumber
              min={1}
              disabled={allVariants.length < 1}
              onChange={(value) => {
                console.log("input value", value);
                const priceAddProduct = hasOffer
                  ? allPrices.find((price) => price.quantity === value)?.price
                  : allPrices.find((price) => price.quantity === 1)?.price *
                    value;
                if (priceAddProduct && checkBoxAddProduct) {
                  form.setFieldsValue({ price: priceAddProduct });
                  setPriceAddProduct(priceAddProduct);
                } else {
                  setPriceAddProduct(0);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Precio"
            name="price"
            rules={[{ required: true, message: "Ingresa un precio." }]}
          >
            <InputNumber
              min={0}
              disabled={allVariants.length < 1}
              readOnly={checkBoxAddProduct}
              value={priceAddProduct}
              onChange={(value) => {
                form.setFieldsValue({ price: value });
                setPriceAddProduct(value);
              }}

              // formatter={format}
              // decimalSeparator=","
            />{" "}
            <br></br>
            <div className="text-xs text-gray-500">
              <Checkbox
                disabled={
                  allVariants.length < 1
                  // ||
                  // allPrices.filter((price) => price.price === priceAddProduct)
                  //   .length < 1
                }
                className="mr-1"
                checked={checkBoxAddProduct}
                onChange={(event) => {
                  const newValueCheckbox = event.target.checked;
                  setCheckBoxAddProduct(newValueCheckbox);
                  if (newValueCheckbox) {
                    const { quantity } = form.getFieldsValue();
                    const newPrice = hasOffer
                      ? allPrices.find((price) => price.quantity === quantity)
                          .price
                      : allPrices.find((price) => price.quantity === 1).price *
                        quantity;

                    console.log("this is quantity inside checkbox", quantity);
                    console.log("this is price inside", newPrice);
                    if (newPrice) {
                      form.setFieldsValue({ price: newPrice });
                      setPriceAddProduct(newPrice);
                    } else {
                      setPriceAddProduct(0);
                    }
                  }
                }}
              />
              Usar precio sugerido
              <br />
              {hasOffer &&
                allPrices.filter(
                  (price) => price.quantity === form.getFieldsValue().quantity
                ).length < 1 &&
                checkBoxAddProduct && (
                  <>
                    <span className="text-xs italic">
                      No hay precio sugerido para esta cantidad.
                    </span>{" "}
                    <Link to={`/products/${form.getFieldsValue().product_id}`}>
                      Ver Producto
                    </Link>
                  </>
                )}
            </div>
          </Form.Item>
          <div className="flex justify-end items-center">
            <Button type="primary" htmlType="submit">
              Agregar
            </Button>
          </div>
        </Form>
      </Modal>
      {/* <div className="">Precio calculado: </div> */}
    </>
    // <Table
    //   components={{
    //     body: {
    //       cell: EditableCell,
    //     },
    //   }}
    //   dataSource={data}
    //   columns={mergedColumns}
    //   rowClassName="editable-row"
    //   pagination={false}
    //   rowKey="order_product_id"
    //   // footer={() => (
    //   //   <Typography.Text strong>
    //   //     Monto Total: {format(totalAmount)}
    //   //   </Typography.Text>
    //   // )}
    // />
  );
};

export default ProductTable;
