export const isDateInPast = (date) => {
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return date < now;
};

export const sameDay = (d1, d2) => {
  if (d1 !== undefined && d2 !== undefined) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  } else {
    return false;
  }
};

export const convertToChileTime = (d) => {
  const localTime = d.getTime();
  const localOffset = d.getTimezoneOffset() * 60000;
  const utc = localTime + localOffset;
  const offset = -4; // UTC-4 to Chile
  return new Date(utc + 3600000 * offset);
};

export const fromDatetoYearMonthDay = (date) => {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
};

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export const getDuplicates = (array, col) => {
  const arrayCol = array.map((item) => item[col]);
  console.log(arrayCol);
  const duplicates = array.filter(
    (item, index) => arrayCol.indexOf(item[col]) !== index
  );
  console.log("Duplicates ", duplicates);
  return duplicates;
};

export const getNextDayISOString = () => {
  const now = new Date();
  const nextDay = new Date(now);
  nextDay.setDate(now.getDate() + 1); // Add one day to the current date
  return nextDay.toISOString();
};

export const findMissingNumberSequence = (arr, lb, ub) => {
  const min = Number(lb);
  const max = Number(ub);
  const missingNumbers = [];

  console.log(min, max);

  for (let i = min; i <= max; i++) {
    console.log("i ", i);
    if (!arr.includes(i)) {
      missingNumbers.push(i);
    }
  }

  return missingNumbers;
};

export const areThereNotFoundProducts = (productsArray) => {
  let result = false;
  productsArray.forEach((product) => {
    if (product.status === "not found") {
      result = true;
    }
  });
  return result;
};

export const groupPrices = (prices) => {
  if (!prices || prices.length === 0) return [];

  const grouped = [];
  let currentGroup = {
    start: prices[0].quantity,
    end: prices[0].quantity,
    unitPrice: getBaseUnitPrice(prices[0].quantity, prices[0].price),
  };

  for (let i = 1; i < prices.length; i++) {
    if (
      currentGroup.unitPrice ===
      getBaseUnitPrice(prices[i].quantity, prices[i].price) // If the current group has the same base price than the one before
    ) {
      currentGroup.end = prices[i].quantity;
    } else {
      // Save the current one
      grouped.push({ ...currentGroup });
      // Start a new one with the current price
      currentGroup = {
        start: prices[i].quantity,
        end: prices[i].quantity,
        unitPrice: getBaseUnitPrice(prices[i].quantity, prices[i].price),
      };
    }
  }
  // Base info
  grouped.push({ ...currentGroup });

  // Adding more information
  grouped.map((item) => (item.single_value = item.start === item.end));
  grouped.map((item) => {
    console.log("Este es el grupo:", item);
    const rangePrices = [];
    for (let quantity = item.start; quantity < item.end + 1; quantity++) {
      rangePrices.push({
        quantity,
        totalPrice:
          quantity < 10
            ? quantity * item.unitPrice - 10
            : quantity * item.unitPrice,
      });
    }
    console.log(rangePrices);
    item.rangePrices = rangePrices;
  });

  return grouped;
};

export const getBaseUnitPrice = (quantity, price) => {
  const mayoristaQuantity = 10;
  if (quantity < mayoristaQuantity) {
    let basePrice = price;
    let reversedPrice = basePrice + 10; // add back the 10
    reversedPrice = reversedPrice; // add back the 10
    reversedPrice = reversedPrice / quantity; // divide by quantity
    return reversedPrice;
  } else {
    // Mayorista amounts
    return price / quantity;
  }
};

export const getStockProducts = (products, allProducts) => {
  const arrStock = [];
  console.log("products: ", products);
  console.log("allProducts: ", allProducts);
  for (let i = 0; i < products.length; i++) {
    const currentProductID = products[i].product_id;
    const currentVariantID = products[i].variant_id;
    console.log("current information", currentProductID, currentVariantID);
    const product = allProducts.find((p) => p.product_id === currentProductID);
    const variant = product?.variants_array.find(
      (v) => v.id === currentVariantID
    );
    console.log("current information", product, variant);
    arrStock.push(variant?.stock);
  }
  return arrStock;
};

export const getLastDays = (today, nDays) => {
  const arrDays = [];
  const lastWeek = new Date(today);
  console.log("last week", lastWeek);
  lastWeek.setDate(today.getDate() - nDays);
  for (let i = 0; i <= nDays; i++) {
    const newDate = new Date(lastWeek);
    newDate.setDate(lastWeek.getDate() + i);
    console.log("new date", newDate);
    arrDays.push(newDate);
  }
  return arrDays;
};

export const getDayOfWeekInSpanish = (date) => {
  const daysInSpanish = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return daysInSpanish[date.getDay()];
};

export const getLocaleDateString = (dateAny) => {
  const dateObject = new Date(dateAny);
  const year = dateObject.getFullYear();
  const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObject.getDate()).slice(-2);
  const dateString = year + "-" + month + "-" + day;
  return dateString;
};
